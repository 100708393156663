import React from "react";
import ChartStory from "../../../../story/ChartStory";
import { ItemTemplateCount } from "./ItemTemplateCount";
import { observer } from "mobx-react-lite";

export const ListTemplateCount = observer(() => {
  return ChartStory.printed_label.map((template) => (
    <ItemTemplateCount key={template.name} template={template} />
  ));
});
