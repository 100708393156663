import React, { useState } from "react";
import ApiData from "../../api/ApiData";
import { ItemSoftCompany } from "./ItemSoftCompany";
import { observer } from "mobx-react-lite";
import FilterData from "../../api/FilterData";
import { BtnFilterVer1 } from "../UI/BtnFilterVer1";
import { useMemo } from "react";
import Media from "../../api/Media";
import Loader from "../Loader";
import { ContainerSubTitleGrey } from "../ContainerSubTitleGrey";
import RolesState from "../../api/RolesState";

export const ListSoftCompany = observer(
  ({
    actionClientLicSet,
    actionClientLicRemove,
    actionClientLicRenewal,
    idSearch,
    setIdSearch,
  }) => {

    // Флаги сортировки
    const [sortSoftVer, setSortSoftVer] = useState(true);
    const [sortNameSoft, setSortNameSoft] = useState(false);
    const [sortLastSession, setSortLastSession] = useState(false);
    // Функция сортировки по версии софта
    const sortSoftVersionFunc = () => {
      if (sortSoftVer) {
        FilterData.sortFuncSoftVersionBA(ApiData.clients_soft);
        setSortSoftVer(false);
      } else {
        FilterData.sortFuncSoftVersionAB(ApiData.clients_soft);
        setSortSoftVer(true);
      }
    };
    // Функция сортировки по имени софта
    const sortSoftNameSoftFunc = () => {
      if (sortNameSoft) {
        FilterData.sortFuncSoftNameBA(ApiData.clients_soft);
        console.log();
        setSortNameSoft(false);
      } else {
        FilterData.sortFuncSoftNameAB(ApiData.clients_soft);
        setSortNameSoft(true);
      }
    };
    // Функция сортировки по последней сессии
    const sortLastSessionFunc = () => {
      if (sortLastSession) {
        FilterData.sortFuncLastSessionBA(ApiData.clients_soft);
        setSortLastSession(false);
      } else {
        FilterData.sortFuncLastSessionAB(ApiData.clients_soft);
        setSortLastSession(true);
      }
    };
    // Фильтр по id clients
    const filterIdClient = useMemo(() => {
      FilterData.searchIdClient(idSearch);
    }, [idSearch]);
    if (ApiData.observableSoft) {
      return <Loader />;
    }

    return (
      <div className="list_text">
        <div className="lic_container_header">
          <span
            className="soft_list_name tabs_lgt_grey_border cr_p text_table_v2"
            onClick={sortSoftNameSoftFunc}
          >
            {!Media.mobile ? (
              <>
                Название ПО <BtnFilterVer1 />
              </>
            ) : (
              "ПО"
            )}
          </span>
          <label className="soft_list_client_id tabs_lgt_grey_border cr_p search_id_client_container text_table_v2">
            {!Media.mobile ? (
              <>
                <span className="">ИД клиента</span>

                <input
                  className="search_id_client"
                  placeholder="Поиск по id clients"
                  onChange={(e) => setIdSearch(e.target.value)}
                  value={idSearch}
                  type="text"
                />
              </>
            ) : (
              "ID"
            )}
          </label>
          {Media.mobile ? (
            <></>
          ) : (
            <span
              className="soft_list_last_session tabs_lgt_grey_border cr_p text_table_v2"
              onClick={sortLastSessionFunc}
            >
              Последняя сессия
              {window.innerWidth > 1280 ? <BtnFilterVer1 /> : <></>}
            </span>
          )}

          <span
            className="soft_list_software_version tabs_lgt_grey_border cr_p text_table_v2"
            onClick={sortSoftVersionFunc}
          >
            {!Media.mobile ? (
              <>
                Версия ПО <BtnFilterVer1 />
              </>
            ) : (
              "Версия"
            )}
          </span>
          {RolesState.unlink_licensing ||
          RolesState.attach_licensing ||
          RolesState.licen_prol_id ? (
            <span className="soft_list_valid_action tabs_lgt_grey_border cr_p text_table_v2">
              {Media.mobile ? "Лиц" : "Действие"}
            </span>
          ) : (
            <></>
          )}
        </div>
        {ApiData.clients_soft.length ? (
          <>
            {ApiData.clients_soft.map((itemsoft) => (
              <ItemSoftCompany
                actionClientLicRemove={actionClientLicRemove}
                actionClientLicSet={actionClientLicSet}
                actionClientLicRenewal={actionClientLicRenewal}
                key={itemsoft.client_id}
                itemsoft={itemsoft}
                software_rel={itemsoft.software_client_rel}
              />
            ))}
          </>
        ) : (
          <ContainerSubTitleGrey text="У компании нет софта" />
        )}
      </div>
    );
  }
);
