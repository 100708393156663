// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --main-blue-1: #2196f3;
  --mast-blue: #236192;
  --mast-yellow: #f0b323;
  --main-gray: #717477ad;
  /* rgb(57 56 56 / 88%); */
  --myGrey: rgb(63 68 73 / 83%);
  --main-bl-gr: #366183;
  --main-lg-bl: rgb(35 37 40);
  --main-green: #4fc3a1;
  --modal_grey: rgb(99 99 99 / 81%);
  --title_grey: #727272;
  --error: #df2727;
  /* Градиенты */
  --main_blu_blk: linear-gradient(var(--mast-blue), var(--main-lg-bl) 50%);
  /* Бордер */
  --lgt-grey-border: 1px solid #0000001f;
}
`, "",{"version":3,"sources":["webpack://./src/style/var.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,oBAAoB;EACpB,sBAAsB;EACtB,sBAAsB;EACtB,yBAAyB;EACzB,6BAA6B;EAC7B,qBAAqB;EACrB,2BAA2B;EAC3B,qBAAqB;EACrB,iCAAiC;EACjC,qBAAqB;EACrB,gBAAgB;EAChB,cAAc;EACd,wEAAwE;EACxE,WAAW;EACX,sCAAsC;AACxC","sourcesContent":[":root {\r\n  --main-blue-1: #2196f3;\r\n  --mast-blue: #236192;\r\n  --mast-yellow: #f0b323;\r\n  --main-gray: #717477ad;\r\n  /* rgb(57 56 56 / 88%); */\r\n  --myGrey: rgb(63 68 73 / 83%);\r\n  --main-bl-gr: #366183;\r\n  --main-lg-bl: rgb(35 37 40);\r\n  --main-green: #4fc3a1;\r\n  --modal_grey: rgb(99 99 99 / 81%);\r\n  --title_grey: #727272;\r\n  --error: #df2727;\r\n  /* Градиенты */\r\n  --main_blu_blk: linear-gradient(var(--mast-blue), var(--main-lg-bl) 50%);\r\n  /* Бордер */\r\n  --lgt-grey-border: 1px solid #0000001f;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
