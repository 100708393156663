import axios from "axios";
import Msg from "./Msg";

export const ApiINNCompany = ({
  inn,
  setNameCompany,
  setCompanyAddress,
  setKpp,
  setLoadingCompanyINN,
}) => {
  const fillINN = () => {
    if (inn.length !== 10 && inn.length !== 12) {
      return Msg.writeMessages("ИНН должно быть 10 или 12 цифр");
    }
    const token = "43f377dca27649b953733f2898e2f0a06200b01b";
    const urlINN =
      "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party";

    const checkINN = {
      method: "POST",
      url: urlINN,
      // mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token " + token,
      },
      data: JSON.stringify({ query: inn }),
    };
    setLoadingCompanyINN(true);
    axios(checkINN)
      .then((res) => {
        setNameCompany(res["data"]["suggestions"][0].value);
        setCompanyAddress(
          res["data"]["suggestions"][0]["data"]["address"].value
        );
        setKpp(res["data"]["suggestions"][0]["data"].kpp);
      })
      .catch((e) => {
        Msg.writeMessages("Убедитесь в правильности введённого ИНН");
        console.log(e);
      })
      .finally(() => {
        setLoadingCompanyINN(false);
      });
  };
  return (
    <span className="fill-inn" onClick={fillINN}>
      Заполнить по ИНН
    </span>
  );
};
