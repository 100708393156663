import React, { useEffect, useRef, useState } from "react";
import ApiData from "../../api/ApiData";
import { ContainerSubTitleGrey } from "../ContainerSubTitleGrey";
import { ListSoftCompany } from "./ListSoftCompany";
import { observer } from "mobx-react-lite";
import FilterData from "../../api/FilterData";
import { ButtonFunctional } from "../UI/ButtonFunctional";
import { ListLicensingNewSelect } from "../licensing/ListLicensingNewSelect";
import { RenewalLicensing } from "../licensing/RenewalLicensing";
import Memory from "../../api/Memory";
import Msg from "../../api/Msg";
import RolesState from "../../api/RolesState";

export const AllSoftCompany = observer(({ idSearch, setIdSearch }) => {
  const refComponent = useRef(null);

  useEffect(() => {
    Memory.writeCoordSoftCardCompany(refComponent.current.offsetTop);
  }, []);

  const [unlincModal, setUnlincModal] = useState(false);
  const [attachModal, setAttachModat] = useState(false);
  // Функция выбора лицензии

  const selectOneLic = () => {
    FilterData.sortFuncvalidPeriodDaysAB(ApiData.licensing_free);
    FilterData.selectlicense();
  };
  // Действие с клиентами/лицензиями
  // Привязать лицензию
  const actionClientLicSet = (e) => {
    if (!RolesState.attach_licensing) {
      return Msg.writeMessages('Нет прав')
    }
    setLicense(e);
    ApiData.getClientSoft(e);
    if (!ApiData.licensing_free.length) {
      return Msg.writeMessages("Нет свободных лицензий");
    }
    selectOneLic();
    setAttachModat(true);
    // }
  };
  // Отвязать лицензию
  const actionClientLicRemove = (e) => {
    ApiData.getClientSoft(e);
    setUnlincModal(true);
  };
  const unlincLicFunc = () => {
    ApiData.unlincLic();
    setUnlincModal(false);
  };
  // Cброс индекса лийцензии
  const resetIndexLicenseFunc = () => {
    setUnlincModal(false);
  };
  // Функция выбора лицензии из списка
  const selectFreeLicFunc = (e) => {
    console.log(e.valid_period_days);
    if (e.valid_period_days >= 0) {
      FilterData.selectFreeLic(e);
      ApiData.attachLic();
      setAttachModat(false);
    } else {
      FilterData.selectFreeLic(e);
      setVisibleRenewalBlock(true);
      setAttachModat(false);
    }
  };

  // Продлить лицензию
  const [visibleRenewalBlock, setVisibleRenewalBlock] = useState(false);
  const [license, setLicense] = useState();

  const actionClientLicRenewal = (e) => {
    setVisibleRenewalBlock(true);

    console.log(e);
    setLicense(e);
  };

  return (
    <div ref={refComponent} className="">
      {ApiData.clients_soft !== undefined ? (
        <>
          <ListSoftCompany
            idSearch={idSearch}
            setIdSearch={setIdSearch}
            actionClientLicRemove={actionClientLicRemove}
            actionClientLicSet={actionClientLicSet}
            actionClientLicRenewal={actionClientLicRenewal}
          />
          {unlincModal ? (
            <div className="grey_container">
              <div className="modal-unlinc-lic-client">
                <p>Необходимо подтвердить действие:</p>
                <p>
                  Отвязать лицензию от клиента
                  <span style={{ color: "var(--mast-blue)" }}>
                    {ApiData.this_client_soft_id.client_id}
                  </span>
                  ?
                </p>
                <div className="btn-container-new-company">
                  <ButtonFunctional onClick={unlincLicFunc}>
                    Отвязать лицензию
                  </ButtonFunctional>
                  <ButtonFunctional onClick={resetIndexLicenseFunc}>
                    Отмена
                  </ButtonFunctional>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <ContainerSubTitleGrey text="Клиенты программного оборудования отсутствуют у данной компании" />
      )}
      {attachModal ? (
        <div className="modal-unlinc-lic-client">
          <p>Доступно лицензий: {ApiData.licensing_info.count_free}</p>
          <p>
            Привязать лицензию к клиенту -{" "}
            <span style={{ color: "var(--mast-blue)" }}>
              {ApiData.this_client_soft_id.client_id}
            </span>
          </p>
          <p></p>

          <div className="btn-container-new-company">
            <ButtonFunctional onClick={() => setAttachModat(false)}>
              Отмена
            </ButtonFunctional>
          </div>

          <ListLicensingNewSelect selectFreeLicFunc={selectFreeLicFunc} />
        </div>
      ) : (
        <></>
      )}
      {visibleRenewalBlock ? (
        <RenewalLicensing
          license={license}
          setVisibleRenewalBlock={setVisibleRenewalBlock}
        />
      ) : (
        <></>
      )}
    </div>
  );
});
