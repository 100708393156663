import React from "react";

const ButtonFunctionalVer2 = ({ children, ...props }) => {
  return (
    <button className="btn_func_container_v2" style={props.style} onClick={props.onClick}>
      <span>{children}</span>
    </button>
  );
};

export default ButtonFunctionalVer2;
