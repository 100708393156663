import React, { useEffect, useState, useMemo } from "react";
import ApiData from "../../api/ApiData";
import { observer } from "mobx-react-lite";
import { ListAllReport } from "../../components/allreport/ListAllReport";
import { FilterByDay } from "../../components/company/FilterByDay";
import FilterData from "../../api/FilterData";
import { BtnSearch } from "../../components/tools/BtnSearch";
import { ExportToExcel } from "../../Export/ExportXLSX";
import { ContainerSubTitleGrey } from "../ContainerSubTitleGrey";

export const ReportLicensing = observer(() => {
  const [period5Cls, setPeriod5Cls] = useState("btn_deactive_period");
  const [period15Cls, setPeriod15Cls] = useState("btn_deactive_period");
  const [period30Cls, setPeriod30Cls] = useState("btn_deactive_period");
  const [period60Cls, setPeriod60Cls] = useState("btn_deactive_period");

  useEffect(() => {
    ApiData.allReportSubscription(ApiData.max_subscription);
    if (ApiData.max_subscription === 5) {
      setPeriod5Cls("btn_active_period");
    } else if (ApiData.max_subscription === 15) {
      setPeriod15Cls("btn_active_period");
    } else if (ApiData.max_subscription === 30) {
      setPeriod30Cls("btn_active_period");
    } else if (ApiData.max_subscription === 60) {
      setPeriod60Cls("btn_active_period");
    }
  }, [ApiData.max_subscription]);

  const selectPeriod = async (e) => {
    setPeriod5Cls("btn_deactive_period");
    setPeriod15Cls("btn_deactive_period");
    setPeriod30Cls("btn_deactive_period");
    setPeriod60Cls("btn_deactive_period");
    await ApiData.selectMaxSubscription(Number(e.target.textContent));
  };

  const [search, setSearch] = useState("");

  const searchCompany = useMemo(() => {
    FilterData.filterDataCompanyReport(search);
  }, [search]);

  // Подготовка к Excel
  let excel = [];
  const preparationExp = useMemo(() => {
    excel = [];
    if (ApiData.subscription !== undefined) {
      ApiData.subscription.forEach((el) => {
        return excel.push({
          Компания: el.company_rel.name_company,
          ИНН: el.company_rel.inn,
          Дней: el.descriptions,
        });
      });
    }
  }, [ApiData.subscription]);

  return (
    <div>
      <h1 className="title_report">Сводный отчёт по лицензиям</h1>
      <p className="text_table_v3 mrg15_0">
        Данный отчёт показывает количество лицензий по компаниям, период "жизни"
        которых истекает в течении выбранного периода
      </p>
      <div className="tabs_lgt_grey_border container_bar_all_report_lic">
        <BtnSearch
          placeholder="Поиск по ИНН и названию компании..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{
            height: "30px",
            border: "var(--lgt-grey-border)",
            marginRight: "10px",
          }}
        />
        <FilterByDay
          onClick={selectPeriod}
          className5={period5Cls}
          className15={period15Cls}
          className30={period30Cls}
          className60={period60Cls}
        />
        <ExportToExcel fileName="Отчёт по компаниям" excel={excel} />
      </div>
      {!ApiData.observableReportLic ? (
        <>
          {ApiData.subscription !== undefined ? (
            <ListAllReport />
          ) : (
            <ContainerSubTitleGrey text="Нет компаний соответствующих выбранному фильтру" />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
});
