import React, { useEffect, useState } from "react";
import axios from "axios";
import { Navigate, useParams } from "react-router-dom";
import useValidationText from "../hooks/useValidationText";
import useValidationPhone from "../hooks/useValidationPhone";
import ApiData from "../api/ApiData";
import { observer } from "mobx-react-lite";
import { EditBtn } from "../components/UI/EditBtn";
import { InputEditVer1 } from "../components/UI/InputEditVer1";
import {ButtonFunctional} from "../components/UI/ButtonFunctional";
import Memory from "../api/Memory";
import { ListOptionRole } from "../components/employees/ListOptionRole";
import User from "../api/User";
import Loader from "../components/Loader";
import RolesState from "../api/RolesState";

const EmploessCard = observer(() => {
  const { id } = useParams();
  const [edit, setEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [phone, setPhone] = useState("");

  const validPhone = useValidationPhone(phone);
  const validFirstName = useValidationText(firstName);
  const validLastName = useValidationText(lastName);
  const validFatherName = useValidationText(fatherName);

  const getPersoneFunc = () => {
    ApiData.getPersone(`user/${id}`);
  };
  useEffect(() => {
    Memory.funcSelectDayPeriodFlag(false);
    getPersoneFunc();
  }, []);

  const showEditor = () => {
    !edit ? setEdit(true) : setEdit(false);
  };

  const editPersone = () => {
    let dataPersone = {};
    if (firstName !== "") {
      dataPersone["first_name"] = firstName;
    }
    if (lastName !== "") {
      dataPersone["last_name"] = lastName;
    }
    if (fatherName !== "") {
      dataPersone["father_name"] = fatherName;
    }
    if (phone !== "") {
      dataPersone["phone"] = phone;
    }
    ApiData.editPerson(dataPersone);
    setEdit(false);
  };

  if (RolesState.emploee_id) {
    return (
      <div className="main-page">
        {!ApiData.observableEmployee ? (
          <>
            <h1 className="card-title">Карточка сотрудника</h1>
            <ul className="card-text-container">
              {RolesState.update_user ? (
                <>
                  {!edit ? (
                    <EditBtn onClick={showEditor} text="Редактировать" />
                  ) : (
                    <div className="flx-sb">
                      <EditBtn
                        onClick={showEditor}
                        text="Отмена редактирования"
                      />

                      {RolesState.reset_password ? (
                        <ButtonFunctional
                          onClick={() => ApiData.resetPass()}
                        >
                          Сбосить пароль
                        </ButtonFunctional>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}

              <li className="card-text-item">
                <span className="employee_card_left_text">ФИО: </span>
                {!edit ? (
                  <>
                    <span>{ApiData.employee.last_name} </span>
                    <span>{ApiData.employee.first_name} </span>
                    <span>{ApiData.employee.father_name} </span>
                  </>
                ) : (
                  <>
                    <InputEditVer1
                      placeholder={ApiData.employee.last_name}
                      value={validLastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <InputEditVer1
                      placeholder={ApiData.employee.first_name}
                      value={validFirstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <InputEditVer1
                      placeholder={ApiData.employee.father_name}
                      value={validFatherName}
                      onChange={(e) => setFatherName(e.target.value)}
                    />
                  </>
                )}
              </li>

              <li className="card-text-item">
                <span className="employee_card_left_text">email: </span>
                {ApiData.employee.email}
              </li>
              {!edit ? (
                <li className="card-text-item">
                  <span>Телефон: </span>
                  {ApiData.employee.phone}
                </li>
              ) : (
                <li className="card-text-item mrg-btm-50">
                  <span className="employee_card_left_text">Телефон: </span>
                  <InputEditVer1
                    placeholder={ApiData.employee.phone}
                    value={validPhone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </li>
              )}

              {RolesState.all_roles ? (
                <>
                  {!edit ? (
                    <li className="card-text-item">
                      <span>Роль: </span>
                      {ApiData.employee["role_rel"] !== null ? (
                        ApiData.employee["role_rel"].role_name
                      ) : (
                        <span>Не выбрана</span>
                      )}
                    </li>
                  ) : (
                    <li className="card-text-item mrg-btm-50">
                      <span className="employee_card_left_text">Роль: </span>
                      <ListOptionRole />
                    </li>
                  )}
                </>
              ) : (
                <></>
              )}
            </ul>
            {!edit ? (
              <></>
            ) : (
              <ButtonFunctional type="submit" onClick={editPersone}>
                Применить
              </ButtonFunctional>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    );
  } else {
    return <Navigate to="/norights" />;
  }
});

export { EmploessCard };
