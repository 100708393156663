import React, { useState } from "react";

export const BtnFuncV4 = (props) => {
  const [text, setText] = useState(props.text);
  return (
    <div className="btn_func_container_v4" onClick={props.onClick}>
      <span className="btn_func_container_v4_span">+</span>
      {text !== undefined ? (
        <span className="btn_func_container_v4_text">{text}</span>
      ) : (
        <></>
      )}
    </div>
  );
};
