import { makeAutoObservable, toJS } from "mobx";
import ApiData from "./ApiData";
import Memory from "./Memory";

class report {
  // Переменные фильтров

  soft_version = [];
  soft_version_error = [];
  flagVersion = true;

  constructor() {
    makeAutoObservable(this);
  }

  hendlerValidLic = async (array, version) => {
    if (version === undefined) {
      return;
    }
    console.log(version);
    const verReg = version.replace(/\./gm, "");
    let tempArray;
    if (Memory.version_reduction) {
      tempArray = ApiData.soft_all_data.filter(
        (s) =>
          Number(
            s.software_version.replace(/\./gm, "").slice(0, verReg.length)
          ) <= Number(verReg)
      );
    } else {
      tempArray = ApiData.soft_all_data.filter(
        (s) =>
          Number(
            s.software_version.replace(/\./gm, "").slice(0, verReg.length)
          ) >= Number(verReg)
      );
    }

    console.log(tempArray);
    tempArray.forEach((el) => {
      const companyID = array.find((i) => el.company_rel.inn === i.inn);
      if (!companyID) {
        array.push({
          inn: el.company_rel.inn,
          name_company: el.company_rel.name_company,
          count: 1,
        });
      } else {
        companyID.count = companyID.count + 1;
      }
    });
    this.flagVersion = false;
  };

  reportSoftVersion = async (valueVersion) => {
    console.log(toJS(ApiData.soft_all_data));
    this.soft_version = [];
    this.flagVersion = true;
    await this.hendlerValidLic(this.soft_version, valueVersion);
  };
}

export default new report();
