import React, { useState, useEffect } from "react";
import { ButtonFunctional } from "../UI/ButtonFunctional";
import { ListRole } from "../admin/ListRole";
import User from "../../api/User";
import { RoleCard } from "./RoleCard";
import { observer } from "mobx-react-lite";
import { NewRoleModal } from "./___NewRoleModal";

export const Role = observer(() => {
  const [visibleRoleCard, setVisibleRoleCard] = useState(false);
  const [visibleNewRole, setVisibleNewRole] = useState(false);

  useEffect(() => {
    User.allRole();
    User.allRights();
  }, []);

  const moveRole = (e) => {
    setVisibleRoleCard(true);
    User.getRole(e);
    User.allRights();
    User.groupRights();
  };

  const removeRole = (e) => {
    User.deleteRole(e);
    User.allRole();
  };

  return (
    <div>
      {" "}
      <div className="admin_sub_nav_container">
        {User.user.permission.includes("post_roles") ||
        User.user.is_superuser ? (
          <ButtonFunctional onClick={() => setVisibleNewRole(true)}>
            Добавить роль
          </ButtonFunctional>
        ) : (
          <></>
        )}

        {visibleNewRole ? (
          <NewRoleModal setVisibleNewRole={setVisibleNewRole} />
        ) : (
          <></>
        )}
      </div>
      <ListRole moveRole={moveRole} removeRole={removeRole} />
      {visibleRoleCard &&
      (User.user.is_superuser ||
        (User.user.permission.includes("get_all_permission") &&
          User.user.permission.includes("get_permission_id") &&
          User.user.permission.includes("get_all_rights"))) ? (
        <RoleCard setVisibleRoleCard={setVisibleRoleCard} />
      ) : (
        <></>
      )}
      {/* <UIPage /> */}
    </div>
  );
});
