import React, { useState } from "react";
import LisrProduction from "../company/LisrProduction";
import ApiData from "../../api/ApiData";
import Loader from "../Loader";
import { observer } from "mobx-react-lite";
import { ContainerSubTitleGrey } from "../ContainerSubTitleGrey";
import { BtnFuncV4 } from "../UI/BtnFuncV4";
import NewProduction from "./NewProduction";
import { ThisProduction } from "./ThisProduction";
import RolesState from "../../api/RolesState";

const Productions = observer(({ getSoftProductionFunc }) => {
  const moveSoftProductionFunc = (e) => {
    ApiData.thisProduction(e.id);
    setFlagThisModalProduction(true);
    console.log(ApiData.this_production);
  };

  const [flagNewModalProduction, setFlagNewModalProduction] = useState(false);
  const [flagThisModalProduction, setFlagThisModalProduction] = useState(false);

  return (
    <div className="production-container-card-company">
      {!flagNewModalProduction ? (
        <>
          <div className="production-title-bar_container flx-sb">
            <span className="li_title ">Площадки</span>

            {RolesState.post_production ? (
              <BtnFuncV4
                onClick={() => setFlagNewModalProduction(true)}
                text="Добавить плошадку"
              />
            ) : (
              <></>
            )}
          </div>
          {!ApiData.observableProduction ? (
            <>
              {ApiData.productions === undefined ? (
                <ContainerSubTitleGrey text="Не добавлено ни одной площадки" />
              ) : (
                <>
                  {!flagThisModalProduction ? (
                    <LisrProduction
                      getSoftProductionFunc={getSoftProductionFunc}
                      moveSoftProductionFunc={moveSoftProductionFunc}
                    />
                  ) : (
                    <ThisProduction
                      setFlagThisModalProduction={setFlagThisModalProduction}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <NewProduction setFlagNewModalProduction={setFlagNewModalProduction} />
      )}
    </div>
  );
});

export default Productions;
