import React from "react";
import User from "../../api/User";
import { ItemRight } from "./ItemRight";
import { observer } from "mobx-react-lite";
import Loader from "../Loader";
import { MyBtnCheckActive } from "../UI/MyBtnCheckActive";
import ApiData from "../../api/ApiData";

export const ListRight = observer(
  ({ actionRole, actionEmploees, actionAdminRole, setAdminClientIDFunc }) => {
    return User.flag_permission ? (
      <div className="admin_permission_select_container">
        {User.group_rights.map((group_category) => (
          <div key={group_category} className="group_category_role_block">
            <div className="group_category_role_title">{group_category}</div>
            <ul className="group_category_role_block_checkbox">
              {User.role_permission_data.map((right) =>
                group_category === right.category &&
                !User.admin_user.includes(right.name_rights) &&
                !User.admin_role.includes(right.name_rights) &&
                !User.admin_clientid.includes(right.name_rights) &&
                !User.patch_subscription.includes(right.name_rights) &&
                right.name_rights !== "patch_superuser_id" &&
                right.name_rights !== "post_verification" &&
                right.name_rights !== "get_all_production" &&
                right.name_rights !== "get_soft_product_id" &&
                right.name_rights !== "get_all_licensing" &&
                right.name_rights !== "get_license_id" &&
                right.name_rights !== "get_subscription" ? (
                  <ItemRight
                    actionRole={actionRole}
                    right={right}
                    key={right.id}
                  />
                ) : (
                  <></>
                )
              )}
            </ul>
          </div>
        ))}
        {User.user.is_superuser ? (
          <ul className="group_category_role_block ">
            <li className="group_category_role_title">
              Администрирование системы
            </li>
            <li className="group_category_role_block_checkbox">
              <label className="flx-sb mrg-btm-10 ">
                <span className="role_item_text">Управление сотрудниками</span>{" "}
                {User.role_permission_data.map((r) =>
                  r.name_rights === "post_user" ? (
                    <>
                      {r.activ ? (
                        <MyBtnCheckActive
                          onClick={() => actionEmploees()}
                          active={r.activ}
                        />
                      ) : (
                        <MyBtnCheckActive
                          onClick={() => actionEmploees()}
                          active={r.activ}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )
                )}
              </label>
              <label className="flx-sb mrg-btm-10 ">
                <span className="role_item_text">Управление ролями</span>{" "}
                {User.role_permission_data.map((r) =>
                  r.name_rights === "get_roles_id" ? (
                    <>
                      {r.activ ? (
                        <MyBtnCheckActive
                          onClick={() => actionAdminRole()}
                          active={r.activ}
                        />
                      ) : (
                        <MyBtnCheckActive
                          onClick={() => actionAdminRole()}
                          active={r.activ}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )
                )}
              </label>
              <label className="flx-sb mrg-btm-10 ">
                <span className="role_item_text">Управление клиентами ИД</span>{" "}
                {User.role_permission_data.map((r) =>
                  r.name_rights === "patch_client_id" ? (
                    <>
                      {r.activ ? (
                        <MyBtnCheckActive
                          onClick={() => setAdminClientIDFunc()}
                          active={r.activ}
                        />
                      ) : (
                        <MyBtnCheckActive
                          onClick={() => setAdminClientIDFunc()}
                          active={r.activ}
                        />
                      )}
                    </>
                  ) : (
                    <></>
                  )
                )}
              </label>
            </li>
          </ul>
        ) : (
          <ul className="group_category_role_block_checkbox">
            <li className="role_item_admin_superuser_title">
              Для настройки прав администратора, обратитесь к сотрудникам:
            </li>
            {ApiData.employees.map((e) =>
              e.is_superuser && e.is_active ? (
                <li className="role_item_admin_superuser_title_user" key={e.id}>
                  {e.last_name} {e.first_name}
                </li>
              ) : (
                <></>
              )
            )}
          </ul>
        )}
      </div>
    ) : (
      <Loader />
    );
  }
);
