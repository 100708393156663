import { useEffect, useState } from "react";

export const useValidationNumber = (value) => {
  const [valueValid, setValueValid] = useState("");

  useEffect(() => {
    if (value.match(/\D/gm)) {
      setValueValid(String(value.replace(/\D/gm, "")));
    } else {
      setValueValid(String(value));
    }
  }, [value]);

  return valueValid;
};
