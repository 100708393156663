import React from "react";
import ApiData from "../../../api/ApiData";
import { ItemClientIdAdmin } from "./ItemClientIdAdmin";
import { observer } from "mobx-react-lite";

export const ListClientIdAdmin = observer(({deleteClient}) => {
  return (
    <div>
      {ApiData.soft_all.map((clientid) => (
        <ItemClientIdAdmin deleteClient={deleteClient} key={clientid.id} clientid={clientid}/>
      ))}
    </div>
  );
});
