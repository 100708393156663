import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import ChartStory from "../../../../story/ChartStory";
import { InputEditVer1 } from "../../../UI/InputEditVer1";

export const ItemTemplateCount = observer((props) => {
  const [w, setW] = useState(props.template.name.match(/[Ww][\d.]*/gm));
  const [h, setH] = useState(props.template.name.match(/[Hh][\d.]*/gm));
  const [g, setG] = useState(props.template.name.match(/[Gg][\d.]*/gm));

  useEffect(() => {
    setW(String(w).replace(/^[Ww]/gm, ""));
    setH(String(h).replace(/^[Hh]/gm, ""));
    setG(String(g).replace(/^[Gg]/gm, ""));
  }, []);

  const [valueLabelAll, setValueLabelAll] = useState(props.template.labels);
  const [valueRibbon, setValueRibbon] = useState(null);

  const changeValueLabel = (e) => {
    setValueLabelAll(e.target.value);
    ChartStory.handlerLabelAll(props.template, e.target.value);
  };

  const changeValueRibbon = (e) => {
    console.log(e.target.value);
    setValueRibbon(e.target.value);
    ChartStory.hendlerRibbon(props.template, e.target.value);
  };

  return (
    <div className="item_company_report_container">
      <span className="item_company_report_template_info_printed">
        ш - {w}, в - {h}, зазор - {g}
      </span>{" "}
      <span className="item_company_report_template_info_printed">
        {props.template.count}
      </span>
      <span className="item_company_report_template_info_printed">
        {Math.floor(props.template.average_day)}
      </span>
      <span className="item_company_report_template_info_printed">
        <InputEditVer1
          type="number"
          value={props.template.labels}
          onChange={changeValueLabel}
          style={{
            width: "80px",
            fontSize: "14px",
          }}
        />
      </span>
      {ChartStory.consumption_label ? (
        <>
          <span className="item_company_report_template_info_printed">
            {Math.floor(
              (props.template.labels - props.template.count) /
                props.template.average_day
            ) > 0
              ? Math.floor(
                  (props.template.labels - props.template.count) /
                    props.template.average_day
                )
              : "нет на балансе"}
          </span>
        </>
      ) : (
        <></>
      )}
      <span className="item_company_report_template_info_printed">
        {ChartStory.ribbon.map((r) => (
          <span key={r.w}>
            {String(r.w) === String(w) ? (
              <>
                <InputEditVer1
                  type="number"
                  value={r.purchased_m}
                  onChange={changeValueRibbon}
                  style={{
                    width: "80px",
                    fontSize: "14px",
                  }}
                />
                ш: {w}
              </>
            ) : (
              ""
            )}
          </span>
        ))}
      </span>
      <span className="item_company_report_template_info_printed">
        {ChartStory.ribbon.map((r) => (
          <span key={r.w}>{String(r.w) === String(w) ? r.m : ""}</span>
        ))}
      </span>
      <span className="item_company_report_template_info_printed">
        {ChartStory.ribbon.map((r) => (
          <span key={r.w}>
            {String(r.w) === String(w) && r.purchased_m - r.m > 0
              ? r.purchased_m - r.m
              : String(r.w) === String(w) && r.purchased_m - r.m <= 0
              ? "Нет на балансе"
              : ""}
          </span>
        ))}
      </span>
    </div>
  );
});
