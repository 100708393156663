import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useValidationText from "../hooks/useValidationText";
import useValidationPhone from "../hooks/useValidationPhone";
import ApiData from "../api/ApiData";
import { observer } from "mobx-react-lite";
import Loader from "../components/Loader";
import { EditBtn } from "../components/UI/EditBtn";
import TextInfoGreyTitle from "../components/UI/TextInfoGreyTitle";
import { ButtonFunctional } from "../components/UI/ButtonFunctional";
import { InputEditVer1 } from "../components/UI/InputEditVer1";
import Memory from "../api/Memory";
import User from "../api/User";
import Msg from "../api/Msg";
import RolesState from "../api/RolesState";
import iconVisiblePass from "../img/icons/visible_pass.png";
import iconVisiblePassClosed from "../img/icons/visible_pass_closed.png";

const UserProfile = observer(() => {
  const [edit, setEdit] = useState(false);
  const [infoFlaf, setInfoFlaf] = useState(true);
  const [optionsFlaf, setOptionsFlaf] = useState(false);
  const [editProfileFlag, setEditProfileFlag] = useState(false);
  const [editPasswordFlag, setEditPasswordFlag] = useState(false);
  const [passVisibleOne, setPassVisibleOne] = useState(false);
  const [passVisibleTwo, setPassVisibleTwo] = useState(false);

  const navigate = useNavigate();

  // Переменные юзера
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fatherName, setFatherName] = useState("");
  // const [fatherName, setFatherName] = useValidationText('');

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setrepeatPassword] = useState("");
  // Хуки
  const validationPhone = useValidationPhone(phone);
  const valueValidFirstName = useValidationText(firstName);
  const valueValidLastName = useValidationText(lastName);
  const valueValidFatherName = useValidationText(fatherName);

  const [classBtnNavInfo, setClassBtnNavInfo] = useState("nav-card-btn-active");

  async function getUserFunc() {
    ApiData.getUser(navigate);
  }

  useEffect(() => {
    Memory.funcSelectDayPeriodFlag(false);
    getUserFunc();
  }, []);

  const showEditor = () => {
    !edit ? setEdit(true) : setEdit(false);
  };

  const navInfo = () => {
    setInfoFlaf(true);
    setOptionsFlaf(false);
    setEdit(false);
    setEditProfileFlag(false);
    setEditPasswordFlag(false);
  };

  const EditPassword = () => {
    if (!editPasswordFlag) {
      setEditProfileFlag(false);
      setEditPasswordFlag(true);
    } else {
      setEditProfileFlag(false);
      setEditPasswordFlag(false);
    }
  };

  const editPasswordFucn = () => {
    if (password !== repeatPassword) {
      Msg.writeMessages("Пароли не совпадают");
    }
    ApiData.updatePasswordUser(password);
    setEditPasswordFlag(false);
    // Msg.writeMessages("");
  };

  const editProfileFucn = () => {
    let dataPersone = {};
    if (firstName !== "") {
      dataPersone["first_name"] = firstName;
    }
    if (lastName !== "") {
      dataPersone["last_name"] = lastName;
    }
    if (fatherName !== "") {
      dataPersone["father_name"] = fatherName;
    }
    if (phone !== "") {
      dataPersone["phone"] = phone;
    }
    setEdit(false);
    console.log(edit, "edit");
    ApiData.updateUser(dataPersone);
    // ApiData.getUser();
  };

  const passVisibleOneFunc = async () => {
    !passVisibleOne ? setPassVisibleOne(true) : setPassVisibleOne(false);
  };

  const passVisibleTwoFunc = async () => {
    !passVisibleTwo ? setPassVisibleTwo(true) : setPassVisibleTwo(false);
  };

  // Выход
  const logOut = () => {
    localStorage.removeItem("User");
    navigate("/login");
  };
  if (RolesState.get_my_profile) {
    return (
      <div className="main-page">
        {!ApiData.observableProfile ? (
          <>
            <h1 className="card-title">Профиль</h1>
            <span className="container_title_edit">
              <span className={classBtnNavInfo} onClick={navInfo}>
                Личная информация
              </span>
              {RolesState.patch_my_profile ? (
                <>
                  {" "}
                  {!edit ? (
                    <EditBtn onClick={showEditor} text="Редактировать" />
                  ) : (
                    <EditBtn
                      onClick={showEditor}
                      text="Отменить редактирование"
                    />
                  )}
                </>
              ) : (
                <></>
              )}
            </span>

            <div className="card-flex-container_v2">
              {!edit ? (
                <div
                  className="card-flex-container_v2"
                  style={{ alignItems: "start" }}
                >
                  <ul className="card-text-container mrg-top-50">
                    <TextInfoGreyTitle
                      editFlag={edit}
                      title="ФИО"
                      text={
                        ApiData.user_profile.last_name +
                        " " +
                        ApiData.user_profile.first_name +
                        " " +
                        ApiData.user_profile.father_name
                      }
                    />
                    <TextInfoGreyTitle
                      editFlag={edit}
                      title="Телефон"
                      text={ApiData.user_profile.phone}
                    />
                    <TextInfoGreyTitle
                      editFlag={edit}
                      title="email"
                      text={ApiData.user_profile.email}
                    />
                    {editPasswordFlag ? (
                      <>
                        <li className="">Введите новый пароль:</li>
                        <li className="update_pass_profile_container">
                          <InputEditVer1
                            placeholder="Придумайте пароль"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            type={!passVisibleOne ? "password" : "text"}
                          />
                          <img
                            className="cls_visible_pass_icon"
                            onClick={passVisibleOneFunc}
                            src={
                              !passVisibleOne
                                ? iconVisiblePass
                                : iconVisiblePassClosed
                            }
                            alt=""
                          />
                        </li>

                        <li className="update_pass_profile_container">
                          <InputEditVer1
                            placeholder="Повторите пароль"
                            value={repeatPassword}
                            onChange={(e) => setrepeatPassword(e.target.value)}
                            type={!passVisibleTwo ? "password" : "text"}
                          />
                          <img
                            className="cls_visible_pass_icon"
                            onClick={passVisibleTwoFunc}
                            src={
                              !passVisibleTwo
                                ? iconVisiblePass
                                : iconVisiblePassClosed
                            }
                            alt=""
                          />
                        </li>

                        <li>
                          <ButtonFunctional onClick={editPasswordFucn}>
                            Сменить пароль
                          </ButtonFunctional>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </ul>

                  {User.user.permission.includes("patch_my_profile") ||
                  User.user.is_superuser ? (
                    <ButtonFunctional onClick={EditPassword}>
                      Сменить пароль
                    </ButtonFunctional>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <ul className="card-text-container mrg-top-50">
                  {/* Отобразить с редактированием */}
                  <TextInfoGreyTitle title="ФИО" editFlag={edit}>
                    <InputEditVer1
                      type="text"
                      placeholder={ApiData.user_profile.last_name}
                      value={ApiData.user_profile.last_name}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <InputEditVer1
                      type="text"
                      placeholder={ApiData.user_profile.first_name}
                      value={ApiData.user_profile.first_name}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <InputEditVer1
                      type="text"
                      placeholder={ApiData.user_profile.father_name}
                      value={ApiData.user_profile.father_name}
                      onChange={(e) => setFatherName(e.target.value)}
                    />
                  </TextInfoGreyTitle>
                  <TextInfoGreyTitle title="Телефон" editFlag={edit}>
                    <InputEditVer1
                      type="text"
                      placeholder={ApiData.user_profile.phone}
                      value={ApiData.user_profile.phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </TextInfoGreyTitle>
                  <TextInfoGreyTitle
                    title="email"
                    text={ApiData.user_profile.email}
                  ></TextInfoGreyTitle>
                  <ButtonFunctional onClick={editProfileFucn}>
                    Подтвердить изменения
                  </ButtonFunctional>
                </ul>
              )}
              <div className="btn-log-out">
                <ButtonFunctional className="btn-log-out" onClick={logOut}>
                  Выход
                </ButtonFunctional>
              </div>
            </div>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    );
  } else {
    return <Navigate to="/norights" />;
  }
});

export { UserProfile };
