import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "../hoc/AuthProvaider";
import ItemInput from "../components/login/ItemInput";
// import useLogin from "../api/useLogin";
import useValidationText from "../hooks/useValidationText";
import useValidationPhone from "../hooks/useValidationPhone";
import ApiData from "../api/ApiData";
import { observer } from "mobx-react-lite";
import Memory from "../api/Memory";
import { ButtonFunctional } from "../components/UI/ButtonFunctional";
import { InputEditVer1 } from "../components/UI/InputEditVer1";

const LoginPage = observer(() => {
  // Переменная выбора рег//логин
  const [registered, setRegistered] = useState(true);
  // Переменные формы
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  // Хуки

  //   Навигация
  const navigate = useNavigate();
  //   Переменные useContext

  useEffect(() => {
    Memory.funcSelectDayPeriodFlag(false);
  }, []);

  const Authorization = (e) => {
    e.preventDefault();
    ApiData.getLogin(login, password, navigate);
  };

  return (
    <div className="login-body">
      <div className="login-container">
        <form className="login_reg-component">
          <h1 className="login_title">Авторизуйтесь</h1>
          {/* <ItemInput
              name="Логин: "
              placeholder="Введите логин/e-mail"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
            <ItemInput
              name="Пароль: "
              placeholder="Введите пароль"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            /> */}
          <ul style={{ width: "90%" }}>
            <li className="mrg-top-12">Логин:</li>
            <li>
              {" "}
              <InputEditVer1
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                placeholder="Введите логин/e-mail"
                style={{ width: "100%" }}
              />
            </li>
            <li className="mrg-top-30 ">Пароль:</li>
            <li className="mrg-btm-10">
              {" "}
              <InputEditVer1
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Введите пароль"
                style={{ width: "100%" }}
              />
            </li>

            <li className="mrg-top-12">
              <ButtonFunctional onClick={Authorization}>Войти</ButtonFunctional>
            </li>
            <li className="grey_text_span_1">
              Прямая регистрация новых пользователей закрыта. Обратитесь к
              админастратору.
            </li>
          </ul>
          <span className="msg-log">{ApiData.message}</span>
        </form>
      </div>
    </div>
  );
});

export { LoginPage };
