// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes animation_show_block {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animation_move_list {
  0% {
    margin-top: -1000px;
  }
  100% {
    margin-top: 0px;
  }
}
@keyframes animation_logo_header {
  50% {
    transform: scale(0.95);
  }
}
`, "",{"version":3,"sources":["webpack://./src/style/animation.css"],"names":[],"mappings":"AAAA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,eAAe;EACjB;AACF;AACA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":["@keyframes animation_show_block {\r\n  0% {\r\n    opacity: 0;\r\n  }\r\n  100% {\r\n    opacity: 1;\r\n  }\r\n}\r\n\r\n@keyframes animation_move_list {\r\n  0% {\r\n    margin-top: -1000px;\r\n  }\r\n  100% {\r\n    margin-top: 0px;\r\n  }\r\n}\r\n@keyframes animation_logo_header {\r\n  50% {\r\n    transform: scale(0.95);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
