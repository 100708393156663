import React, { useEffect, useState } from "react";

const TextInfoGreyTitle = ({ children, ...props }) => {
  return (
    <ul>
      <li className="li_title">{props.title}</li>
      {!props.editFlag ? (
        <li className="card-text-item">{props.text}</li>
      ) : (
        <li className="card-text-item">{children}</li>
      )}
    </ul>
  );
};

export default TextInfoGreyTitle;
