import React from "react";
import User from "../../api/User";
import { ItemRole } from "./ItemRole";
import { observer } from "mobx-react-lite";

export const ListRole = observer(({ moveRole, removeRole }) => {
  if (User.all_role !== undefined) {
    return User.all_role.map((role) => (
      <ItemRole
        role={role}
        key={role.id}
        moveRole={moveRole}
        removeRole={removeRole}
      />
    ));
  }
});
