import React from "react";
import AlertData from "../../api/AlertData";
import { observer } from "mobx-react-lite";
import Memory from "../../api/Memory";
import { useNavigate } from "react-router-dom";

export const ErrorLic15Day = observer(() => {

    const navigate = useNavigate()

    const goToReport = () => {
        Memory.funcSelectDayPeriod(15)
        navigate('/allreport')
    }
  return (
    <ul className="alert_error_container " onClick={goToReport}>
      <li className="alert_error_text">Тип: Уведомление</li>
      <li className="alert_error_text">У <span className="error">{AlertData.counter_error_supscription}</span> компаний закончится подписка в течение 15 дней. Нажмите, чтобы перейти в отчёт.</li>
    </ul>
  );
});
