import React from "react";
import ApiData from "../api/ApiData";
import { ErrorLic15Day } from "../components/alert/ErrorLic15Day";
import { ContainerSubTitleGrey } from "../components/ContainerSubTitleGrey";
import User from "../api/User";
import RolesState from "../api/RolesState";

const AlertCenter = () => {
  if (RolesState.all_soft_client) {
    return (
      <div className="main-page">
        <h1 className="card-title">Центр уведомлений</h1>
        {ApiData.report_lic_activ_error ? <ErrorLic15Day /> : <></>}
        {!ApiData.report_lic_activ_error ? (
          <ContainerSubTitleGrey text="Уведомлени отсутствуют" />
        ) : (
          <></>
        )}
      </div>
    );
  }
};

export { AlertCenter };
