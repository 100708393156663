import React, { useState } from "react";
import { ListTemplateCount } from "./ListTemplateCount";
import ChartStory from "../../../../story/ChartStory";
import { observer } from "mobx-react-lite";
import { BtnSizeVer1 } from "../../../UI/button/BtnSizeVer1";
import { InputEditVer1 } from "../../../UI/InputEditVer1";
import { ButtonFunctional } from "../../../UI/ButtonFunctional";

export const TemplateCountTextReport = observer(() => {
  const [valueDateMin, setValueDateMin] = useState(
    String(ChartStory.printed_label_min).replace(/[.]/gm, "-")
  );
  const [valueDateMax, setValueDateMax] = useState(
    String(ChartStory.printed_label_max).replace(/[.]/gm, "-")
  );
  //   const [valueLabelAll, setValueLabelAll] = useState(ChartStory.label_all);

  const [clsBtnCalc, setClsBtnCalc] = useState("btn_active_period");
  const [valueBtnCalc, setValueBtnCalc] = useState("вкл");

  const setDateMin = (e) => {
    setValueDateMin(e.target.value);
    ChartStory.printedLabelMin(e.target.value.replace(/[-]/gm, "."));
  };

  const setDateMax = (e) => {
    setValueDateMax(e.target.value);
    ChartStory.printedLabelMax(e.target.value.replace(/[-]/gm, "."));
  };

  const calculationVisible = () => {
    if (!ChartStory.consumption_label) {
      ChartStory.handlerConsuptionLabel(true);
      setClsBtnCalc("btn_active_period");
      setValueBtnCalc(" вкл");
    } else {
      ChartStory.handlerConsuptionLabel(false);
      setClsBtnCalc("btn_deactive_period");
      setValueBtnCalc("откл");
    }
  };

  //   const changeValueLabel = (e) => {
  //     setValueLabelAll(e.target.value);
  //     ChartStory.handlerLabelAll(e.target.value);
  //   };

  return (
    <div className="company_report_text_container">
      <div className="chart_container_nav_text_v1">
        <p>Отчёт по количеству напечатанных этикеток</p>
        <label className="chart_container_label">
          От <input type="date" value={valueDateMin} onChange={setDateMin} />
          До <input type="date" value={valueDateMax} onChange={setDateMax} />
          {/* Этикеток закуплено:{" "} */}
          {/* <InputEditVer1
            type="number"
            value={valueLabelAll}
            onChange={changeValueLabel}
            style={{
              width: "90px",
              fontSize: "14px",
            }}
          /> */}
          <span
            className={clsBtnCalc}
            onClick={calculationVisible}
            style={{
              display: "inline-block",
              minWidth: "110px",
              fontSize: "14px",
            }}
          >
            Расчёт {valueBtnCalc}.
          </span>
          <ButtonFunctional
            onClick={() => {
              ChartStory.printedLabel(
                // valueLabelAll,
                ChartStory.printed_label_min,
                ChartStory.printed_label_max
              );
            }}
            style={{
              height: "32px",
            }}
          >
            Показать
          </ButtonFunctional>
        </label>
      </div>
      <div className="list_template_count_text_header">
        <span className="item_company_report_template_info_printed text_12">
          Параметры этикетки
        </span>
        <span className="item_company_report_template_info_printed text_12">
          Напечатано за период (шт)
        </span>
        <span className="item_company_report_template_info_printed text_12">
          Среднее за сутки (шт)
        </span>
        {ChartStory.consumption_label ? (
          <>
            {" "}
            <span className="item_company_report_template_info_printed text_12">
              Закуплено этикеток (в штуках)
            </span>
            <span className="item_company_report_template_info_printed text_12">
              Этикеток осталось примерно на (в днях)
            </span>
            <span className="item_company_report_template_info_printed text_12">
              Закуплено риббона (в метрах)
            </span>
            <span className="item_company_report_template_info_printed text_12">
              Использовано риббона (в метрах)
            </span>
            <span className="item_company_report_template_info_printed text_12">
              Осталось риббона (в метрах)
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <ListTemplateCount />
    </div>
  );
});
