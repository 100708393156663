import React from "react";
import iconFilter from "../../img/icons/filter.png";
import { observer } from "mobx-react-lite";

const ItemProduction = observer((props) => {
  
  return (
    <div className="item-production-container">
      <ul
        className="item-ul-production item-production-text-container"
        onClick={() => props.moveSoftProductionFunc(props.itemproduction)}
      >
        <li className="card-text-item_v2">
          Площадка: {props.itemproduction.name_production}
        </li>

        {props.itemproduction.address_production.length < 45 ? (
          <li className="card-text-item_v2">
            {" "}
            Адрес: {props.itemproduction.address_production}
          </li>
        ) : (
          <li className="card-text-item_v2">
            {" "}
            Адрес: {props.itemproduction.address_production.slice(0, 45)}...
          </li>
        )}

      </ul>
      <img
        className="item-production-filter-img"
        onClick={() => props.getSoftProductionFunc(props.itemproduction)}
        src={iconFilter}
        alt=""
      />
    </div>
  );
});

export default ItemProduction;
