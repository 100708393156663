import { makeAutoObservable, runInAction } from "mobx";
import ApiData from "./ApiData";
import RolesState from "./RolesState";

class alert {
  cls_visible_alert = "header_alert"; //Класс колокольчика уведомлений
  counter_error_supscription = 0; // Число ошибок по лицензиям
  counter_error = 0; // Общее число ошибок

  constructor() {
    makeAutoObservable(this);
  }

  getAllReportError = async () => {
    if (localStorage.getItem("User") === null) return;
    if (RolesState.subscription_rights) {
      this.report_lic_activ_error_data = [];
      this.report_lic_activ_error = [];
      await ApiData.allReportSubscription(15);
    }
  };

  //   Расчёт всех ошибок в ЛК
  calcError = async () => {
    try {
      this.counter_error_supscription = 0;
      await this.getAllReportError();
    } catch (e) {
      console.error(e);
    } finally {
      // setTimeout(() => {
        // console.log(ApiData.subscription);
        this.counter_error_supscription = ApiData.subscription.length;
      // }, 1000);
    }
    runInAction(() => {
      this.counter_error = this.counter_error_supscription;
      if (this.counter_error > 0) {
        this.cls_visible_alert = "header_alert";
      } else {
        this.cls_visible_alert = "hidden";
      }
    });
  };
}

export default new alert();
