import { Navigate, Outlet, useNavigate } from "react-router-dom";
import ApiData from "../api/ApiData";
import { observer } from "mobx-react-lite";
import User from "../api/User";
import { useEffect } from "react";

const PrivateUserAuth = observer(() => {

  useEffect(() => {
    // console.log(JSON.parse(localStorage.getItem("User")).permission)
  }, [])

  if(JSON.parse(localStorage.getItem("User")) === null) {
    return <Navigate to="/login" />;
  } 
  if (JSON.parse(localStorage.getItem("User")).permission === undefined) {
    localStorage.removeItem("User")
    alert('Старый токен удалён. Если переход на страницу авторизации не произошёл автоматически, обновите страницу вручную.')
  } 
   if (ApiData.isAuth && localStorage.getItem("User") !== null) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
});

export { PrivateUserAuth };
