import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";

export const FilterByDay = observer((props) => {
  const [period5Cls, setPeriod5Cls] = useState(props.period5Cls);
  const [period15Cls, setPeriod15Cls] = useState(props.period5Cls);
  const [period30Cls, setPeriod30Cls] = useState(props.period5Cls);
  const [period60Cls, setPeriod60Cls] = useState(props.period5Cls);

  const location = useLocation();

    return (
    <div className="client_filterbar_show_period_container">
      <span className={period5Cls || props.className5} onClick={props.onClick}>
        5
      </span>
      <span
        className={period15Cls || props.className15}
        onClick={props.onClick}
      >
        15
      </span>
      <span
        className={period30Cls || props.className30}
        onClick={props.onClick}
      >
        30
      </span>
      <span
        className={period60Cls || props.className60}
        onClick={props.onClick}
      >
        60
      </span>
      <span className="client_filterbar_show_period_span">Выберите период</span>
    </div>
  );
});
