import React, { useEffect } from "react";
import ApiData from "../../api/ApiData";
import { ItemLicensingNewSelect } from "./ItemLicensingNewSelect";
import { observer } from "mobx-react-lite";
import FilterData from "../../api/FilterData";

export const ListLicensingNewSelect = observer(({ selectFreeLicFunc }) => {
  useEffect(() => {
    FilterData.sortFuncvalidPeriodDaysBA(ApiData.licensing_free);
  }, []);
  return (
    <ul className="list_free_lic_container">
      <li>
        <span
          className="list_free_lic padding_7_5 tabs_lgt_grey_border title_grey"
          style={{
            width: window.innerWidth > 1100 ? "" : "90px",
          }}
        >
          Активна до{" "}
        </span>
        <span className="list_free_lic_name padding_7_5 tabs_lgt_grey_border title_grey">
          {window.innerWidth < 1100 ? "Лицензии" : "Тип лицензии"}
        </span>
        {window.innerWidth > 1100 ? (
          <span className="list_free_lic padding_7_5 tabs_lgt_grey_border title_grey">
            Истекает через
          </span>
        ) : (
          <span
            className="list_free_lic padding_7_5 tabs_lgt_grey_border title_grey"
            style={{
              width: window.innerWidth > 1100 ? "" : "50px",
            }}
          >
            Дни
          </span>
        )}
        <span
          className="list_free_lic padding_7_5 tabs_lgt_grey_border title_grey"
          style={{
            width: window.innerWidth > 1100 ? "" : "105px",
          }}
        >
          Действие
        </span>
      </li>
      {ApiData.licensing_free.map((lic) => (
        <ItemLicensingNewSelect
          key={lic.id_pin_code}
          selectFreeLicFunc={selectFreeLicFunc}
          lic={lic}
        />
      ))}
    </ul>
  );
});
