import React from "react";

export const InputEditVer1 = (props) => {
  return (
    <input
      className="input_edit_v1"
      type={props.type}
      style={props.style}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
