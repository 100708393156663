import React, { useState } from "react";
import { InputEditVer1 } from "../UI/InputEditVer1";
import { BtnFuncV4 } from "../UI/BtnFuncV4";
import { BtnClosedVer1 } from "../UI/BtnClosedVer1";
import User from "../../api/User";
import { observer } from "mobx-react-lite";

export const NewRoleModal = observer(({ setVisibleNewRole }) => {
  const [body, setBody] = useState("");

  const addRoleFunc = () => {
    // e.preventDefault()
    if (body.length < 3) {
      return alert("Слишком короткое имя роли");
    }
    User.addRole(body);

    // User.allPermission();
    // User.allRights();
    setBody("");
    setVisibleNewRole(false);
  };

  const closedContainerNewRole = () => {
    setVisibleNewRole(false);
    setBody("");
  };

  return (
    <div className="admin_add_role_container">
      <InputEditVer1
        value={body}
        onChange={(e) => setBody(e.target.value)}
        placeholder="Введите имя роли"
        style={{ width: "70%" }}
      />
      <BtnFuncV4 onClick={addRoleFunc} />
      <BtnClosedVer1 onClick={closedContainerNewRole} />
    </div>
  );
});
