import React from "react";
import Loader from "../Loader";

const ModalLoader = () => {
  return (
    <div className="modal-loader">
      <Loader />
    </div>
  );
};

export default ModalLoader;
