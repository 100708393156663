import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ItemCompany from "./ItemCompany";
import { BtnSearch } from "../tools/BtnSearch";
import { ButtonFunctional } from "../UI/ButtonFunctional";
import { observer } from "mobx-react-lite";
import User from "../../api/User";
import Media from "../../api/Media";
import { BtnFuncV4 } from "../UI/BtnFuncV4";
import Msg from "../../api/Msg";
import { ContainerSubTitleGrey } from "../ContainerSubTitleGrey";
import ApiData from "../../api/ApiData";
import RolesState from "../../api/RolesState";

const ListCompany = observer(
  ({ companys, onClickAddNew, value, setSearch }) => {
    const handlerErrorCompanyCard = () => {
      Msg.writeMessages("У вас нет прав, для просмотра карточки компании");
    };
    const navigate = useNavigate();
    const toCompany = async (company) => {
      try {
        await ApiData.saveThisCompany(company);
      } catch (e) {
        console.log(e);
      } finally {
        navigate(`/company/${ApiData.company.inn}`);
      }
    };

    return (
      <div className="container-list">
        <div className="cls-tools">
          <BtnSearch
            placeholder="Поиск по ИНН и названию компании..."
            value={value}
            onChange={(e) => setSearch(e.target.value)}
          />

          {User.user.permission.includes("post_company") ||
          User.user.is_superuser ? (
            <>
              {" "}
              {!Media.mobile ? (
                <ButtonFunctional onClick={onClickAddNew} type_btn="1">
                  Добавить
                </ButtonFunctional>
              ) : (
                <BtnFuncV4 onClick={onClickAddNew}></BtnFuncV4>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="container-list-el">
          <span className="text-table item-company-name ">Название</span>
          <span className="text-table item-company-inn ">ИНН</span>
          {!Media.mobile ? (
            <span className=" text-table item-company-address">Адрес</span>
          ) : (
            <></>
          )}
        </div>
        <div className="cls-list">
          {companys.length > 0 ? (
            <>
              {" "}
              {RolesState.company_id
                ? companys.map((company) => (
                    <Link onClick={() => toCompany(company)} key={company.inn}>
                      <ItemCompany company={company} />
                    </Link>
                  ))
                : companys.map((company) => (
                    <div
                      onClick={handlerErrorCompanyCard}
                      key={company.inn}
                      to={`/company/${company.inn}`}
                    >
                      <ItemCompany company={company} />
                    </div>
                  ))}
            </>
          ) : (
            <>
              {value.length === 0 ? (
                <ContainerSubTitleGrey text="В базе данных не добавлено ни одной компании" />
              ) : (
                <ContainerSubTitleGrey text="Не найдено компаний, удовлетворяющих запросу. Если компания была добавлена вами только что, подождите, пожалуйста, 1 минуту и проверьте повторно." />
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

export default ListCompany;
