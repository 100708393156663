import React from "react";
import Memory from "../../api/Memory";
import { observer } from "mobx-react-lite";
import Media from "../../api/Media";

export const ItemAllReport = observer((props) => {
  const getActiveDate = () => {
    const dt = new Date().getTime();
    const dt2 = new Date(props.lic.validity_date).getTime();
    return Math.ceil((dt2 - dt) / (1000 * 60 * 60 * 24));
  };

  return (
    <div
      className="lic_container_item"
      onClick={() => props.toCompany(props.lic)}
    >
      {!Media.mobile ? (
        <span className="rep_lic_list_name padding_7_5 tabs_lgt_grey_border">
          {props.lic.company_rel.name_company}
        </span>
      ) : (
        <span className="rep_lic_list_name padding_7_5 tabs_lgt_grey_border">
          {props.lic.company_rel.name_company.length > 16
            ? props.lic.company_rel.name_company.slice(0, 16) + "..."
            : props.lic.company_rel.name_company}
        </span>
      )}

      <span className="rep_list_inn padding_7_5 tabs_lgt_grey_border">
        {props.lic.company_rel.inn}
      </span>
      <span className="rep_lic_counter padding_7_5 tabs_lgt_grey_border">
        {getActiveDate()}
      </span>

      <span className="rep_list_valid_period_days padding_7_5 tabs_lgt_grey_border">
        {Memory.validity_date}
        {!Media.mobile ? props.lic.validity_date : ""}
      </span>
    </div>
  );
});
