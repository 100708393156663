import { observer } from "mobx-react-lite";
import React from "react";


export const ItemRole = observer((props) => {
  return (
    <div className="item_role_container">
      <div className="item_role" onClick={() => props.moveRole(props.role)}>
        {props.role.role_name}
      </div>
      <div className="item_role_icone_remove" onClick={() => props.removeRole(props.role)} ></div>
    </div>
  );
});
