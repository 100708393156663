// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.test_red {
    color: brown;
}
.test_green {
    color: green;
}
.test_blue {
    color: blue;
}`, "",{"version":3,"sources":["webpack://./src/style/test.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,WAAW;AACf","sourcesContent":[".test_red {\r\n    color: brown;\r\n}\r\n.test_green {\r\n    color: green;\r\n}\r\n.test_blue {\r\n    color: blue;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
