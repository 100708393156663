import { observer } from "mobx-react-lite";
import React from "react";
import ApiData from "../../api/ApiData";

export const ItemLicensingNewSelect = observer((props) => {

  const checkLicensing = () => {
    console.log(props.lic);
    ApiData.checkLic(props.lic.id_license);
  };

  return (
    <li className="flex-alligment">
      {" "}
      <span
        className="list_free_lic padding_7_5 tabs_lgt_grey_border"
        style={{
          width: window.innerWidth > 1100 ? "" : "90px",
          fontSize: window.innerWidth > 1100 ? "" : "12px",
        }}
      >
        {props.lic.valid_period_days >= 9000
          ? "Бессрочно"
          : props.lic.activ_data}
      </span>
      {props.lic.type_software === "old" ? (
        <span className="list_free_lic_name padding_7_5 tabs_lgt_grey_border lic_update_res">
          Обновите
        </span>
      ) : (
        <span className="list_free_lic_name padding_7_5 tabs_lgt_grey_border">
          {props.lic.valid_period_days >= 0 ? (
            props.lic.type_software
          ) : (
            <span className="error">Истекла</span>
          )}
        </span>
      )}
      {props.lic.valid_period_days > 0 ? (
        <span
          className="list_free_lic padding_7_5 tabs_lgt_grey_border"
          style={{
            width: window.innerWidth > 1100 ? "" : "50px",
          }}
        >
          {props.lic.valid_period_days >= 9000
            ? "Бессрочно"
            : props.lic.valid_period_days}
        </span>
      ) : (
        <span
          className="list_free_lic padding_7_5 tabs_lgt_grey_border error"
          style={{
            width: window.innerWidth > 1100 ? "" : "50px",
          }}
        >
          Истека
        </span>
      )}
      <span
        onClick={() => props.selectFreeLicFunc(props.lic)}
        className="lic_container_item list_free_lic padding_7_5 tabs_lgt_grey_border"
        style={{
          width: window.innerWidth > 1100 ? "" : "105px",
        }}
      >
        {props.lic.valid_period_days >= 0 ? (
          <span>Привязать</span>
        ) : (
          <span>Продлить</span>
        )}
      </span>
      <span onClick={checkLicensing}>?</span>
    </li>
  );
});
