import React, { useEffect, useState } from "react";
import ListEmploees from "../components/employees/ListEmploees";
import ApiData from "../api/ApiData";
import { observer } from "mobx-react-lite";
import Loader from "../components/Loader";
import Memory from "../api/Memory";
import { Navigate } from "react-router-dom";
import User from "../api/User";
import RolesState from "../api/RolesState";

const Employees = observer(() => {
  async function getDataFunc() {
    await ApiData.getDataEmployees();
  }

  useEffect(() => {
    Memory.funcSelectDayPeriodFlag(false);
    getDataFunc();
  }, []);


  if (RolesState.emploees) {
    return (
      <div className="main-page">
        {!ApiData.isLoading ? (
          <ListEmploees users={ApiData.employees_dates} />
        ) : (
          <Loader />
        )}
      </div>
    );
  } else {
    return <Navigate to="/norights" />;
  }
});

export { Employees };
