import React from 'react'

const ItemInput = (props) => {
  return (
    <label className="login-label">
            {" "}
            <span className='login-label-span'>{props.name} </span>
            <input
              className="login_reg-input"
              placeholder={props.placeholder}
              type={props.type}
              value={props.value}
              onChange={props.onChange}
            />{" "}
          </label>
  )
}

export default ItemInput