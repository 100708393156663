import React from "react";
import FilterData from "../../api/FilterData";
import iconRemoveFilter from "../../img/icons/remove_filter_v3.png";
import iconNoProductionFilter from "../../img/icons/icon_no_production.png";
import { observer } from "mobx-react-lite";
import Memory from "../../api/Memory";

export const FilterBarClient = observer(
  ({ setIdSearch, setVersionFilterOn }) => {
    const showNullProduction = () => {
      FilterData.sortSoftProductionNull();
      Memory.funcFilterByProduction("Без площадки");
    };
    const showAllClientsFunc = () => {
      setIdSearch("");
      FilterData.showAllClients();
      setVersionFilterOn(false);
    };

    return (
      <div className="client_filterbar_container">
        {Memory.var_filter_by_production !== null ? (
          <span className="client_filterbar_production">
            Площадка: {Memory.var_filter_by_production}
          </span>
        ) : (
          <></>
        )}
        {Memory.select_day_period ? (
          <span className="client_filterbar_production">
            Период: {Memory.day_active_lic}
          </span>
        ) : (
          <></>
        )}
        <label className="client_filterbar_show_no_production_container">
          <img
            className="client_filterbar_show_all_client_img"
            src={iconNoProductionFilter}
            alt=""
            onClick={showNullProduction}
          />
          <span className="client_filterbar_show_no_production_span">
            Клиенты без площадки
          </span>
        </label>
        <label className="client_filterbar_show_all_client_container">
          <img
            className="client_filterbar_show_all_client_img"
            src={iconRemoveFilter}
            alt=""
            onClick={showAllClientsFunc}
          />
          <span className="client_filterbar_show_all_client_span">
            Сбросить все фильтры
          </span>
        </label>
      </div>
    );
  }
);
