import React, { useEffect, useMemo, useState } from "react";
import ApiData from "../../../api/ApiData";
import { ListClientIdAdmin } from "./ListClientIdAdmin";
import { observer } from "mobx-react-lite";
import { ModalWaringClientID } from "./ModalWaringClientID";
import Media from "../../../api/Media";
import FilterData from "../../../api/FilterData";
import Loader from "../../Loader";

export const ClientIdAdmin = observer(() => {
  useEffect(() => {
    ApiData.getAllSoft();
  }, []);

  const [modalWaringFlag, setModalWaringFlag] = useState(false);
  const [clientID, setClientID] = useState(null);

  const deleteClient = (e) => {
    setModalWaringFlag(true);
    setClientID(e);
  };

  const [idSearch, setIdSearch] = useState("");

  // Фильтр по id clients
  const filterIdClient = useMemo(() => {
    FilterData.searchIdClientAllSoft(idSearch);
  }, [idSearch]);

  return (
    <div>
      <div className="lic_container_header">
        <label className="client_id_admin_header tabs_lgt_grey_border text_table_v2">
          {!Media.mobile ? (
            <>
              <span className="">ИД клиента</span>
              <input
                className="search_id_client"
                placeholder="Поиск по id clients"
                onChange={(e) => setIdSearch(e.target.value)}
                value={idSearch}
                type="text"
              />
            </>
          ) : (
            "ID"
          )}
        </label>
        <span className="item_client_id_admin_name tabs_lgt_grey_border text_table_v2">
          {!Media.mobile ? "Название" : "Имя"}
        </span>
        <span className="item_client_id_admin_company tabs_lgt_grey_border text_table_v2">
          Компания
        </span>
        <span className="item_client_id_admin_btn-header tabs_lgt_grey_border text_table_v2">
          Отвязать
        </span>
      </div>
      {!ApiData.observableSoftAll ? (
        <ListClientIdAdmin deleteClient={deleteClient} />
      ) : (
        <Loader />
      )}

      {modalWaringFlag ? (
        <ModalWaringClientID
          clientID={clientID}
          setModalWaringFlag={setModalWaringFlag}
        />
      ) : (
        <></>
      )}
    </div>
  );
});
