import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import iconProfileV2 from "../img/icons/icons-profile_v2.png";
import iconProfileActiveV2 from "../img/icons/icons-profile_active_v2.png";

import { observer } from "mobx-react-lite";
import { AlertCenterHeader } from "./alert/AlertCenterHeader";
import alertIcon from "../img/icons/icon_alert.png";
import AlertData from "../api/AlertData";
import User from "../api/User";
import arrowHeader from "../img/icons/icon-arrow_header.png";
import arrowHeaderActive from "../img/icons/icon-arrow_header_active.png";
import iconCompanys from "../img/icons/icon-company_v2.png";
import iconCompanysActive from "../img/icons/icon-company_active_v2.png";

import iconEmployees from "../img/icons/icon-employees.png";
import iconEmployeesActive from "../img/icons/icon-employees-active.png";

import iconReport from "../img/icons/icon-report_v2.png";
import iconReportActive from "../img/icons/icon-report_active_v2.png";

import iconReportAdminPanel from "../img/icons/icon-technical-support.png";
import iconReportAdminPanelActive from "../img/icons/icon-technical-support-active.png";
import ApiData from "../api/ApiData";
import Media from "../api/Media";
import RolesState from "../api/RolesState";

const Header = observer(() => {
  // const [companyRights, setCompanyRights] = useState(
  //   User.user.permission.includes("get_all_company") || User.user.is_superuser
  // );
  // const [emploeesRights, setEmploeesRights] = useState(
  //   User.user.permission.includes("get_all_user") || User.user.is_superuser
  // );
  // const [reportRights, setReportRights] = useState(
  //   User.user.permission.includes("get_all_soft_client") ||
  //     User.user.is_superuser
  // );
  // const [adminRights, setAdminRights] = useState(
  //   User.user.permission.includes("post_user") ||
  //     User.user.permission.includes("get_roles_id") ||
  //     User.user.permission.includes("patch_client_id") ||
  //     User.user.is_superuser
  // );

  const location = useLocation();

  const handlerHeaderBar = (flag) => {
    if (flag) {
      setWidthHeader(40);
      setPaddingHeader("15px 3px 90px");
    } else {
      setWidthHeader(180);
      setPaddingHeader("15px 15px 40px 15px");
    }
  };

  useEffect(() => {
    handlerHeaderBar(Media.mobile);
  }, [Media.mobile]);

  const checkSubscriptionRight = async () => {
    setTimeout(() => {
      if (RolesState.subscription_rights) {
        ApiData.allReportSubscription();
      }
    }, 1000);
  };

  useEffect(() => {
    checkSubscriptionRight();
  }, []);

  const [visibleHeader, setVisibleHeader] = useState(true);
  const [widthHeader, setWidthHeader] = useState(180);
  const [paddingHeader, setPaddingHeader] = useState("15px 15px 40px 15px");

  const hoverArrow = (e) => {
    e.target.src = arrowHeaderActive;
  };
  const hoverArrowOut = (e) => {
    e.target.src = arrowHeader;
  };

  const visibleBar = (e) => {
    if (visibleHeader) {
      setVisibleHeader(false);
      e.target.style.transform = "rotate(0deg)";
    } else {
      setVisibleHeader(true);
      e.target.style.transform = "rotate(180deg)";
    }
    handlerHeaderBar(visibleHeader);
  };

  return (
    <div
      className="header"
      style={{ minWidth: widthHeader + "px", padding: paddingHeader }}
    >
      {location.pathname === "/login" ||
      localStorage.getItem("User") === null ||
      location.pathname === "/" ? (
        <>
          {!Media.mobile ? (
            <div className="header__title-logo-container">
              <NavLink to={"/company"}>
                <h1 className="header__title-logo-dmc">DMC</h1>
                <p className="header__title-logo-lk">личный кабинет</p>
              </NavLink>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {" "}
          <nav className="nav-container">
            {!Media.mobile ? (
              <label className="media_btn_header_container">
                <img
                  onClick={visibleBar}
                  src={arrowHeader}
                  onMouseMove={hoverArrow}
                  onMouseOut={hoverArrowOut}
                  alt=""
                  className="media_btn_header"
                />
              </label>
            ) : (
              <></>
            )}

            <div className="header__title-logo-container">
              {Media.mobile || !visibleHeader ? (
                <></>
              ) : (
                <NavLink to={"/company"}>
                  <h1 className="header__title-logo-dmc">DMC</h1>
                  <p className="header__title-logo-lk">личный кабинет</p>
                </NavLink>
              )}

              {RolesState.subscription_rights ? (
                <NavLink
                  className={AlertData.cls_visible_alert}
                  to={"/alertcenter"}
                >
                  <img
                    className="alert_error_counter_header_icon_animation_on"
                    src={alertIcon}
                    alt=""
                  />
                  {!ApiData.observableReportLic ? <AlertCenterHeader /> : <></>}
                </NavLink>
              ) : (
                <></>
              )}
            </div>
            <nav className="nav-menu">
              {RolesState.companys ? (
                <NavLink className="link-header" to={"/company"}>
                  {Media.mobile || !visibleHeader ? (
                    <>
                      {location.pathname === "/company" ||
                      location.pathname ===
                        `/company/${ApiData.company.inn}` ? (
                        <img
                          className="header_icon_nav_active"
                          src={iconCompanysActive}
                          alt=""
                        />
                      ) : (
                        <img
                          className="header_icon_nav"
                          src={iconCompanys}
                          alt=""
                        />
                      )}
                    </>
                  ) : (
                    <span>Компании</span>
                  )}
                </NavLink>
              ) : (
                <></>
              )}
              {RolesState.emploees ? (
                <NavLink className="link-header" to={"/employees"}>
                  {Media.mobile || !visibleHeader ? (
                    <>
                      {location.pathname === "/employees" ||
                      location.pathname ===
                        `/employees/${ApiData.employee.id}` ? (
                        <img
                          className="header_icon_nav_active"
                          src={iconEmployeesActive}
                          alt=""
                        />
                      ) : (
                        <img
                          className="header_icon_nav"
                          src={iconEmployees}
                          alt=""
                        />
                      )}
                    </>
                  ) : (
                    <span>Сотрудники</span>
                  )}
                </NavLink>
              ) : (
                <></>
              )}
              {RolesState.all_soft_client || RolesState.subscription_rights ? (
                <NavLink className="link-header" to={"/allreport"}>
                  {Media.mobile || !visibleHeader ? (
                    <>
                      {location.pathname === "/allreport" ? (
                        <img
                          className="header_icon_nav_active"
                          src={iconReportActive}
                          alt=""
                        />
                      ) : (
                        <img
                          className="header_icon_nav"
                          src={iconReport}
                          alt=""
                        />
                      )}
                    </>
                  ) : (
                    <span>Отчёты</span>
                  )}
                </NavLink>
              ) : (
                <></>
              )}

              {RolesState.admin_panel ? (
                <NavLink className="link-header" to={"/adminpanel"}>
                  {Media.mobile || !visibleHeader ? (
                    <>
                      {location.pathname === "/adminpanel" ? (
                        <img
                          className="header_icon_nav_active"
                          src={iconReportAdminPanelActive}
                          alt=""
                        />
                      ) : (
                        <img
                          className="header_icon_nav"
                          src={iconReportAdminPanel}
                          alt=""
                        />
                      )}
                    </>
                  ) : (
                    <span>Админ панель</span>
                  )}
                </NavLink>
              ) : (
                <></>
              )}
            </nav>
          </nav>
          {location.pathname !== "/profile" ? (
            <>
              {User.user.is_superuser ||
              User.user.permission.includes("get_my_profile") ? (
                <NavLink className="lk-nav" to={"/profile"}>
                  <img
                    className="header_icon_nav "
                    src={iconProfileV2}
                    alt=""
                  />
                  {Media.mobile || !visibleHeader ? (
                    <></>
                  ) : (
                    <div className="lk-nav-name">
                      <span>{User.user.last_name}</span>
                      <span>{User.user.first_name}</span>
                    </div>
                  )}
                </NavLink>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {User.user.is_superuser ||
              User.user.permission.includes("get_my_profile") ? (
                <NavLink className="lk-nav" to={"/profile"}>
                  <img
                    className="header_icon_nav_active"
                    src={iconProfileActiveV2}
                    alt=""
                  />
                  {Media.mobile || !visibleHeader ? (
                    <></>
                  ) : (
                    <div className="lk-nav-name">
                      <span>{User.user.last_name}</span>
                      <span>{User.user.first_name}</span>
                    </div>
                  )}

                  {/* <img className="icon-profile" src={iconProfileV2} alt="" />
                  <div className="lk-nav-name-active">
                    <span>{User.user.last_name}</span>
                    <span>{User.user.first_name}</span>
                  </div> */}
                </NavLink>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
});

export default Header;
