import React, { useEffect, useState } from "react";
import ApiData from "../api/ApiData";
import Loader from "../components/Loader";
import { observer } from "mobx-react-lite";
import { ReportLicensing } from "../components/allreport/ReportLicensing";
import User from "../api/User";
import { ContainerSubTitleGrey } from "../components/ContainerSubTitleGrey";
import { Navigate } from "react-router-dom";
import { VersionReport } from "../components/allreport/version/VersionReport";
import RolesState from "../api/RolesState";

const AllReport = observer(() => {
  // const [subscription_rights, setsubscription_rights] = useState(
  //   User.user.permission.includes("valid_subscription") ||
  //     User.user.is_superuser
  // );
  // const [allSoftRights, setallSoftRights] = useState(
  //   User.user.permission.includes("get_all_soft_client") ||
  //     User.user.is_superuser
  // );

  useEffect(() => {
    if (RolesState.subscription_rights) {
      setFlagLic(true);
      setClassBtnNavLic("nav-card-btn-active");
    } else if (RolesState.all_soft_client && !RolesState.subscription_rights) {
      setFlagSoftVersion(true);
      setClassBtnNavSoftVersion("nav-card-btn-active");
    }
  }, []);

  const [classBtnNavLic, setClassBtnNavLic] = useState("nav-card-btn");
  const [classBtnNavSoftVersion, setClassBtnNavSoftVersion] =
    useState("nav-card-btn");
  const [flagLic, setFlagLic] = useState(false);
  const [flagSoftVersion, setFlagSoftVersion] = useState(false);

  const navLic = () => {
    setFlagLic(true);
    setFlagSoftVersion(false);
    setClassBtnNavSoftVersion("nav-card-btn");
    setClassBtnNavLic("nav-card-btn-active");
  };
  const navSoftVersion = () => {
    setFlagLic(false);
    setFlagSoftVersion(true);
    setClassBtnNavSoftVersion("nav-card-btn-active");
    setClassBtnNavLic("nav-card-btn");
  };

  if (RolesState.all_soft_client || RolesState.subscription_rights) {
    return (
      <div className="main-page ">
        <h1 className="card-title">Отчёты</h1>
        {!ApiData.isLoading ? (
          <>
            <div>
              <div className="company_container_submenu_nav">
                {RolesState.subscription_rights ? (
                  <span className={classBtnNavLic} onClick={navLic}>
                    Подписки
                  </span>
                ) : (
                  <></>
                )}
                {RolesState.all_soft_client ? (
                  <span
                    className={classBtnNavSoftVersion}
                    onClick={navSoftVersion}
                  >
                    Версия софта
                  </span>
                ) : (
                  <></>
                )}
              </div>
              {flagLic && RolesState.subscription_rights ? (
                <ReportLicensing />
              ) : (
                <></>
              )}
              {flagSoftVersion && RolesState.all_soft_client ? (
                <VersionReport />
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>
            <Loader />
          </>
        )}
      </div>
    );
  } else {
    return <Navigate to="/norights" />;
  }
});
export { AllReport };
