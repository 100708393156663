import { useEffect, useState } from "react";

const useValidationPhone = (value) => {
  const [valueValid, setValueValid] = useState("");

  useEffect(() => {
    if (value.match(/^8/gm)) {
      setValueValid(String(value.replace(/^8/gm, "7")));
    } else if (value.match(/^9/gm)) {
      setValueValid(String(value.replace(/^/gm, "7")));
    } else if (value.match(/\D/gm)) {
      setValueValid(String(value.replace(/\D/gm, "")));
    } else {
      setValueValid(String(value));
    }
  }, [value]);

  return valueValid;
};

export default useValidationPhone;
