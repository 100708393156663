// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cls-tools {
    /* margin: 15px 10px 0 0; */
    position: sticky;
    z-index: 2;
    right: 0;
    top: 0;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 2%;
    width: 100%;
    background: white;
    justify-content: flex-end;
}
.inp-search-tools {
  height: 38px;
  width: 300px;
  border: 1px solid var(--main-gray);
  border-radius: 6px;
}
.exportToExcel {
  margin-right: 5px;
    display: inline-block;
    cursor: pointer;
    border: var(--lgt-grey-border);
    padding: 2px 9px;
    border-radius: 6px;
    font-weight: 500;
    transition: all 0.4s ease-in;
    line-height: 150%;
    min-width: 40px;
    text-align: center;
    color: green;
    min-height: 30px;
}
.exportToExcel:hover {
  color: white;
  background: green;

}
.inp-search-tools:focus {
  outline: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/style/tools.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,gBAAgB;IAChB,UAAU;IACV,QAAQ;IACR,MAAM;IACN,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,OAAO;IACP,WAAW;IACX,iBAAiB;IACjB,yBAAyB;AAC7B;AACA;EACE,YAAY;EACZ,YAAY;EACZ,kCAAkC;EAClC,kBAAkB;AACpB;AACA;EACE,iBAAiB;IACf,qBAAqB;IACrB,eAAe;IACf,8BAA8B;IAC9B,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;IAC5B,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;AACA;EACE,YAAY;EACZ,iBAAiB;;AAEnB;AACA;EACE,wBAAwB;AAC1B","sourcesContent":[".cls-tools {\r\n    /* margin: 15px 10px 0 0; */\r\n    position: sticky;\r\n    z-index: 2;\r\n    right: 0;\r\n    top: 0;\r\n    padding: 5px;\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 2%;\r\n    width: 100%;\r\n    background: white;\r\n    justify-content: flex-end;\r\n}\r\n.inp-search-tools {\r\n  height: 38px;\r\n  width: 300px;\r\n  border: 1px solid var(--main-gray);\r\n  border-radius: 6px;\r\n}\r\n.exportToExcel {\r\n  margin-right: 5px;\r\n    display: inline-block;\r\n    cursor: pointer;\r\n    border: var(--lgt-grey-border);\r\n    padding: 2px 9px;\r\n    border-radius: 6px;\r\n    font-weight: 500;\r\n    transition: all 0.4s ease-in;\r\n    line-height: 150%;\r\n    min-width: 40px;\r\n    text-align: center;\r\n    color: green;\r\n    min-height: 30px;\r\n}\r\n.exportToExcel:hover {\r\n  color: white;\r\n  background: green;\r\n\r\n}\r\n.inp-search-tools:focus {\r\n  outline: none !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
