import { makeAutoObservable, toJS } from "mobx";
import { req } from "../api/api.config";
import ApiData from "../api/ApiData";
import axios from "axios";
class ChartStory {
  chart_data = [];
  company_chart_data = [
    { name: "PRINT", templates: [], days: [] },
    { name: "VERIFY", devaice_id: [], days: [] },
    { name: "DEFECT", devaice_id: [], days: [] },
  ];
  company_chart_data_print = [];
  company_chart_data_verify_defect = [];
  company_chart_data_defect = [];
  observableChartCompany = false;
  inn_company = null;

  printed_label = [];
  ribbon = [];
  label_all = 50000;
  printed_label_min = "";
  printed_label_max = "";
  consumption_label = true; // Показать / скрыть потребление

  constructor() {
    makeAutoObservable(this);
  }

  color_chart = [
    "#0000ff",
    "#2cdf67",
    "#c92a1d",
    "#c91dc9",
    "#1dc9c0",
    "#f3f30d",
    "#6f3305",
    "#a92dd4",
  ]; //#0000ff50
  color_chart_id = 0;
  incrementColorId = () => {
    this.color_chart_id = this.color_chart_id + 1;
  };

  // По печати
  PRINT = {
    labels: [],
    datasets: [],
  };
  // Верифицировано и отбраковано
  VERIFY_DEFECT = {
    labels: ["Верифицировано", "Отбраковано"],
    datasets: [
      {
        label: "Линия",
        data: [],
        backgroundColor: ["#28c628", "red"],
        borderWidth: 2,
        hoverOffset: 10,
      },
    ],
  };
  // Отбраковано детально
  DEFECT = {
    labels: ["Не удалось прочитать", "Дубликат", "Низкое качество"],
    datasets: [
      {
        label: "Линия",
        data: [],
        backgroundColor: ["#28c628", "red", "blue"],
        borderWidth: 2,
        hoverOffset: 10,
      },
    ],
  };
  // Отчёты графики

  asyncGet = async (url) => {
    const res = await req.get(url);
    return res.data["data"].response;
  };
  asyncGetChart = async () => {
    this.chart_data = [];
    let result = {};
    let total; // 1 450 429
    const size = 50;
    let page = 1;
    try {
      const res = await req.get(
        `events_log/${ApiData.company.inn}?size=${size}&number=${page}`
      );
      this.chart_data = res.data["data"].response;
      total = res.data["data"].total_records;
      page++;
    } catch (e) {
      console.log(e);
    } finally {
      // const count_req = Math.ceil((total - size) / size);
      // const allReq = [];
      // while (page < count_req) {
      //   allReq.push(
      //     await this.asyncGet(
      //       `events_log/${ApiData.company.inn}?size=${size}&number=${page}`
      //     )
      //   );
      //   page++;
      //   console.log(toJS(allReq));
      // }
      // const allRes = await axios.all([
      //   allReq.map(async (allReq) => {
      //     return (this.chart_data = this.chart_data.concat(allReq));
      //   }),
      // ]);
      // console.log(toJS(allRes));
    }

    return this.chart_data;
  };
  // По компании
  getCompanyGraph = async () => {
    this.color_chart_id = 0;
    this.observableChartCompany = true;

    try {
      let result;
      try {
        result = await this.asyncGetChart();
      } catch (e) {
        console.log(e);
      } finally {
        console.log(result);
        if (JSON.stringify(result) !== "{}" || result !== undefined) {
          console.log(result);
          // Записываем данные по печати
          this.company_chart_data_print = result.filter(
            (event) => event.event_name === "PRINT"
          );
          // Записываем данные по верификации и браку
          this.company_chart_data_verify_defect = result.filter(
            (event) =>
              event.event_name === "VERIFY" || event.event_name === "DEFECT"
          );
          // Записываем инн компании
          this.inn_company = result[0].inn;
          // Собираем структуру для графиков по принтерам
          const idPrinterEvent = this.company_chart_data.find(
            (print) => print.name === "PRINT"
          );
          this.company_chart_data_print.forEach((el) => {
            // Ищем объект с событием принтера
            if (idPrinterEvent) {
              // Записываем имена всех шаблонов
              const nameTemplate = idPrinterEvent.templates.find(
                (name) => name === el.data
              );
              if (!nameTemplate) {
                idPrinterEvent.templates.push(el.data);
              }
              // Проверяем, есть ли в событиях принтера дата
              const findEventDay = idPrinterEvent.days.find(
                (day) => day.day === new Date(el.timestamp).toLocaleDateString()
              );
              // Если этого дня нет в событие, то мы его добавляем
              if (!findEventDay) {
                idPrinterEvent.days = [
                  ...idPrinterEvent.days,
                  {
                    day: new Date(el.timestamp).toLocaleDateString(),
                    template: [
                      {
                        name: el.data,
                        date: new Date(el.timestamp).toLocaleDateString(),
                        count: 1,
                      },
                    ],
                  },
                ];
              } else {
                const findTemplate = findEventDay.template.find(
                  (t) => t.name === el.data
                );
                if (findTemplate) {
                  // console.log(toJS(findTemplate));
                  findTemplate.count++;
                } else {
                  findEventDay.template.push({
                    name: el.data,
                    date: new Date(el.timestamp).toLocaleDateString(),
                    count: 1,
                  });
                }
              }
            }
          });
          this.sortDaysAB(idPrinterEvent.days);
          // Собираем структурупо верификации и браку
          const idSerialVerify = this.company_chart_data.find(
            (verify) => verify.name === "VERIFY"
          );
          const idSerialDefect = this.company_chart_data.find(
            (defect) => defect.name === "DEFECT"
          );
          console.log(toJS(this.company_chart_data_verify_defect));
          this.company_chart_data_verify_defect.forEach((el) => {
            // Верификация

            if (idSerialVerify && el.event_name === "VERIFY") {
              // console.log(idSerialVerify);

              // Проверяем, есть ли в событиях принтера дата
              const findEventDay = idSerialVerify.days.find(
                (day) => day.day === new Date(el.timestamp).toLocaleDateString()
              );

              if (!findEventDay) {
                idSerialVerify.days = [
                  ...idSerialVerify.days,
                  {
                    day: new Date(el.timestamp).toLocaleDateString(),
                    devaice_id: [
                      {
                        id: el.device_id,
                        date: new Date(el.timestamp).toLocaleDateString(),
                        count: 1,
                      },
                    ],
                  },
                ];
              } else {
                const findDevaiceID = findEventDay.devaice_id.find(
                  (t) => t.devaice_id === el.devaice_id
                );
                if (findDevaiceID) {
                  findDevaiceID.count++;
                } else {
                  findEventDay.devaice_id.push({
                    id: el.device_id,
                    date: new Date(el.timestamp).toLocaleDateString(),
                    count: 1,
                  });
                }
              }
            }
            // Брак
            if (idSerialDefect && el.event_name === "DEFECT") {
              // console.log(idSerialVerify);

              // Проверяем, есть ли в событиях принтера дата
              const findEventDay = idSerialDefect.days.find(
                (day) => day.day === new Date(el.timestamp).toLocaleDateString()
              );

              if (!findEventDay) {
                idSerialDefect.days = [
                  ...idSerialDefect.days,
                  {
                    day: new Date(el.timestamp).toLocaleDateString(),
                    devaice_id: [
                      {
                        id: el.device_id,
                        date: new Date(el.timestamp).toLocaleDateString(),
                        error: [
                          {
                            name: el.data,
                            count: 1,
                          },
                        ],
                      },
                    ],
                  },
                ];
              } else {
                const findDevaiceID = findEventDay.devaice_id.find(
                  (d) => d.id === el.device_id
                );
                // console.log(toJS(findDevaiceID))
                if (findDevaiceID) {
                  const findDevaiceIDError = findDevaiceID.error.find(
                    (e) => e.name === el.data
                  );
                  if (findDevaiceIDError) {
                    findDevaiceIDError.count++;
                  } else {
                    findDevaiceID.error.push({
                      name: el.data,
                      count: 1,
                    });
                  }
                }
              }
            }
          });

          console.log(toJS(this.company_chart_data));
        } else {
          console.log(result["data"].response);
          this.inn_company = null;
          this.PRINT = {
            // inn: null,
            labels: [],
            datasets: [],
          };
          this.VERIFY_DEFECT = {
            labels: ["Верифицировано", "Отбраковано"],
            datasets: [
              {
                label: "Линия 1",
                data: [],
                borderColor: ["green", "red"],
                // backgroundColor: "#0000ff78",
                borderWidth: 2,
                hoverOffset: 20,
              },
            ],
          };
          this.company_chart_data = [
            { name: "PRINT", templates: [], days: [] },
            { name: "VERIFY", devaice_id: [], days: [] },
            { name: "DEFECT", devaice_id: [], days: [] },
          ];
          return;
        }
      }
    } catch (e) {
      console.log(e);
      return;
    } finally {
      this.observableChartCompany = false;
      this.iteration();
    }
  };
  // Собираем графики
  // По печати
  iterationPrintGhart = () => {
    const idPrinterEvent = this.company_chart_data.find(
      (print) => print.name === "PRINT"
    );
    if (idPrinterEvent) {
      idPrinterEvent.days.forEach((day) => {
        this.PRINT.labels.push(day.day);
        // this.sortDaysAB(this.PRINT.labels);
        idPrinterEvent.templates.forEach((t) => {
          const findTemplateToPrint = this.PRINT.datasets.find(
            (p) => t === p.label
          );
          if (!findTemplateToPrint) {
            this.PRINT.datasets.push({
              label: t,
              data: [],
              borderColor: this.color_chart[this.color_chart_id],
              backgroundColor: this.color_chart[this.color_chart_id] + "50",
              borderWidth: 2,
              hoverOffset: 20,
            });
            this.incrementColorId();
          }
        });
        idPrinterEvent.templates.forEach((t) => {
          const findTemplateToPrint = this.PRINT.datasets.find(
            (p) => t === p.label
          );
          const findTemplate = day.template.find((name) => t === name.name);
          if (findTemplate) {
            findTemplateToPrint.data.push(findTemplate.count);
          } else {
            findTemplateToPrint.data.push(0);
          }
        });
      });
    }
    console.log(1);
    this.printedLabel();
  };
  // Верификация и брак (общий)
  iterationVerDef = () => {
    const idSerialVerify = this.company_chart_data.find(
      (verify) => verify.name === "VERIFY"
    );
    let verifyCount = 0;
    let defectCountNOREAD = 0;
    let defectCountDUPLICATE = 0;
    let defectCountLOWGRADE = 0;

    // console.log(toJS(idSerialVerify))
    if (idSerialVerify) {
      idSerialVerify.days.forEach((el) => {
        el.devaice_id.forEach((devaice) => {
          verifyCount = verifyCount + devaice.count;
        });
      });
      console.log(toJS(this.VERIFY_DEFECT.datasets[0].data));
      this.VERIFY_DEFECT.datasets[0].data.push(verifyCount);
    }
    const idSerialDefect = this.company_chart_data.find(
      (defect) => defect.name === "DEFECT"
    );
    if (idSerialDefect) {
      idSerialDefect.days.forEach((el) => {
        el.devaice_id.forEach((devaice) => {
          // console.log(toJS(devaice));
          devaice.error.forEach((e) => {
            if (e.name === "NOREAD") {
              defectCountNOREAD = defectCountNOREAD + e.count;
            }
            if (e.name === "DUPLICATE") {
              defectCountDUPLICATE = defectCountDUPLICATE + e.count;
            }
            if (e.name === "LOWGRADE") {
              defectCountLOWGRADE = defectCountLOWGRADE + e.count;
            }
            // console.log(e);
            // defectCountNOREAD = defectCountNOREAD + e.count;
          });
        });
      });
      this.VERIFY_DEFECT.datasets[0].data.push(
        defectCountNOREAD + defectCountDUPLICATE + defectCountLOWGRADE
      );
      this.DEFECT.datasets[0].data.push(
        defectCountNOREAD,
        defectCountDUPLICATE,
        defectCountLOWGRADE
      );
    }
  };
  //   Общая функция собирания графиков
  iteration = () => {
    this.iterationPrintGhart();
    this.iterationVerDef();

    console.log(this.VERIFY_DEFECT);
    console.log(toJS(this.DEFECT));
  };

  // Сортировки
  // Дни на увеличение
  sortDaysAB(arr) {
    arr.sort((a, b) => {
      if (a.day < b.day) {
        return -1;
      }
      if (a.day > b.day) {
        return 1;
      }
      return 0;
    });
  }

  // Сбор текстового отчёта по напечатанным этикеткам
  handlerLabelAll = (idName, count_label) => {
    this.printed_label.forEach((t) => {
      if (idName.name === t.name) {
        t.labels = Number(count_label);
      }
    });
    console.log(toJS(this.printed_label));
  };

  hendlerRibbon = (idName, count_ribbon) => {
    this.ribbon.forEach((r) => {
      if (idName.w === r.w) {
        r.purchased_m = Number(count_ribbon);
      }
    });
  };

  handlerConsuptionLabel = (boolean) => {
    this.consumption_label = boolean;
  };

  printedLabelMin = (min) => {
    this.printed_label_min = min;
  };
  printedLabelMax = (max) => {
    this.printed_label_max = max;
  };
  printedLabel = async (min, max) => {
    // console.log(2)
    this.printed_label = [];
    const idPrinterEvent = this.company_chart_data.find(
      (print) => print.name === "PRINT"
    );
    if (idPrinterEvent.days[0] === undefined) {
      return;
    }
    if (!min) {
      const MinDayArray = idPrinterEvent.days[0].day.split(".");
      this.printedLabelMin(
        MinDayArray[2] + "." + MinDayArray[1] + "." + MinDayArray[0]
      );
    } else {
      this.printedLabelMin(min);
    }
    if (!max) {
      const MinDayArray =
        idPrinterEvent.days[idPrinterEvent.days.length - 1].day.split(".");
      this.printedLabelMax(
        MinDayArray[2] + "." + MinDayArray[1] + "." + MinDayArray[0]
      );
    } else {
      this.printedLabelMax(max);
    }
    idPrinterEvent.days.forEach((el) => {
      const elDayArray = el.day.split(".");
      const elDay = elDayArray[2] + "." + elDayArray[1] + "." + elDayArray[0];

      if (
        new Date(elDay).getTime() >=
          new Date(this.printed_label_min).getTime() &&
        new Date(elDay).getTime() <= new Date(this.printed_label_max).getTime()
      ) {
        // console.log(toJS(el));
        el.template.forEach((t) => {
          const findPrinted = this.printed_label.find((p) => p.name === t.name);
          if (!findPrinted) {
            this.printed_label = [
              ...this.printed_label,
              {
                name: t.name,
                count: t.count,
                day_count: 1,
                labels: 50000,
                w: Number(
                  String(t.name.match(/[Ww][\d.]*/gm)).replace(/^[Ww]/gm, "")
                ),
              },
            ];
          } else {
            findPrinted.count = findPrinted.count + t.count;
            findPrinted.day_count = findPrinted.day_count + 1;
          }
        });
        this.printed_label.forEach((el) => {
          el.average_day = el.count / el.day_count;
        });
      }
    });
    this.ribbonCalculation();
    console.log("this.printed_label", toJS(this.printed_label));
  };
  // Расчёт рибона
  ribbonCalculation = () => {
    this.ribbon = [];
    this.printed_label.forEach((el) => {
      const sizeRibbon = this.ribbon.find((r) => r.w === el.w);
      if (!sizeRibbon) {
        this.ribbon = [
          ...this.ribbon,
          {
            w: el.w,
            m: Math.floor(
              ((Number(
                String(el.name.match(/[Hh][\d.]*/gm)).replace(/^[Hh]/gm, "")
              ) +
                Number(
                  String(el.name.match(/[Gg][\d.]*/gm)).replace(/^[Gg]/gm, "")
                )) *
                el.count) /
                1000
            ),
            purchased_m: 1000,
          },
        ];
      } else {
        console.log(sizeRibbon);
        sizeRibbon.m = Math.floor(
          sizeRibbon.m +
            ((Number(
              String(el.name.match(/[Hh][\d.]*/gm)).replace(/^[Hh]/gm, "")
            ) +
              Number(
                String(el.name.match(/[Gg][\d.]*/gm)).replace(/^[Gg]/gm, "")
              )) *
              el.count) /
              1000
        );
      }
    });
    console.log(toJS(this.ribbon), "this.ribbon");
  };
}
export default new ChartStory();
