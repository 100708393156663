import { observer } from "mobx-react-lite";
import React from "react";

const ItemEmployee = observer((props) => {
  return (
    <div className="container-item-el">
      <div className="item-employee-info item-cosmetic">
        {props.employee.last_name}
      </div>
      <div className="item-employee-info item-cosmetic">
        {props.employee.first_name}
      </div>
      {window.innerWidth > 1100 ? (
        <div className="item-employee-info item-cosmetic">
          {props.employee.father_name}
        </div>
      ) : (
        <></>
      )}

      {window.innerWidth > 1100 ? (
        <div className="item-employee-info item-cosmetic">
          {props.employee.email}
        </div>
      ) : (
        <></>
      )}
      <div className="item-employee-info item-cosmetic">
        {props.employee.phone}
      </div>
    </div>
  );
});

export default ItemEmployee;
