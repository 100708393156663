import React, { useEffect } from "react";
import AlertData from "../../api/AlertData";
import { observer } from "mobx-react-lite";

export const AlertCenterHeader = observer(() => {
  let intervalUpdateAlert;

  useEffect(() => {
    AlertData.calcError();
    intervalUpdateAlert = null;
    intervalUpdateAlert = setInterval(() => {
      AlertData.calcError();
    }, 10 * 60 * 1000);
    return () => clearInterval(intervalUpdateAlert);
  }, []);

  return (
    <div className="alert_error_counter_header_container">
      <span className="alert_error_counter_header_calc">
        {AlertData.counter_error}
      </span>
    </div>
  );
});
