
import React, { useState } from "react";

export const ButtonFunctional = ({ children, ...props }) => {
  const [typeBtn, setTypeBtn] = useState(props.type_btn);
  return (
    // <button className="btn_func_container " onClick={props.onClick}>
    <button className="btn_func_container" style={props.style}  onClick={props.onClick}>

      {/* Тип 1 добавить */}
      {typeBtn === "1" ? (
        <div className="add-icon-UI">
          <span>+</span>
        </div>
      ) : (
        <></>
      )}
      {typeBtn === "2" ? (
        <div className="add-icon-UI">
          <span style={{fontSize: '25px'}}>X</span>
        </div>
      ) : (
        <></>
      )}
      <span>{children}</span>
    </button>
  );
};
