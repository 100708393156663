import React, { useState, useMemo, useEffect } from "react";
import { BtnSearch } from "../../tools/BtnSearch";
import FilterData from "../../../api/FilterData";
import ApiData from "../../../api/ApiData";
import ReportData from "../../../api/ReportData";
import { ListVersionReport } from "./ListVersionReport";
import { observer } from "mobx-react-lite";
import Loader from "../../Loader";
import { useNavigate } from "react-router-dom";
import { ExportToExcel } from "../../../Export/ExportXLSX";
import Memory from "../../../api/Memory";

export const VersionReport = observer(() => {
  const [valueVersion, setValueVersion] = useState("1.0.3");
  const [valueUpdate, setValueUpdate] = useState("меньше");

  const [clsDirectionVersion, setClsDirectionVersion] = useState(
    "btn_deactive_period"
  );
  const directionVersion = async () => {
    try {
      if (Memory.version_reduction) {
        setClsDirectionVersion("btn_active_period");
        setValueUpdate("больше");
      } else {
        setClsDirectionVersion("btn_deactive_period");
        setValueUpdate("меньше");
      }
      await Memory.setVersionReduction();
    } catch (e) {
    } finally {
      ReportData.reportSoftVersion(valueVersion);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    ApiData.getAllSoft();
    ReportData.reportSoftVersion(valueVersion);
  }, [ApiData.soft_all_data]);

  const [search, setSearch] = useState("");

  const searchCompany = useMemo(() => {
    FilterData.filterDataCompanyReport(search);
  }, [search]);

  const hendlerValueVersion = (e) => {
    setValueVersion(e.target.value);
    ReportData.reportSoftVersion(e.target.value);
  };

  const moveCompany = (e) => {
    sessionStorage.setItem("soft_version", valueVersion);
    navigate(`/company/${e.inn}`);
  };

  // Подготовка к Excel
  let excel = [];
  const preparationExp = useMemo(() => {
    excel = [];
    ReportData.soft_version.forEach((el) => {
      excel.push({
        Компания: el.name_company,
        ИНН: el.inn,
        Количество: el.count,
      });
    });
  }, [ReportData.soft_version]);

  return (
    <div>
      <h1 className="title_report">Сводный отчёт по версии софта</h1>
      <p className="text_table_v3 mrg15_0">
        Данный отчёт показывает количество компаний, у которых имеется
        устаревшая версия программного обеспечения
      </p>
      <div className="tabs_lgt_grey_border container_bar_all_report_lic">
        <div>
          {/* <BtnSearch
            placeholder="Поиск по ИНН и названию компании..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{
              height: "30px",
              border: "var(--lgt-grey-border)",
              marginRight: "10px",
            }}
          />{" "} */}
          <span onClick={directionVersion} className={clsDirectionVersion}>
            {valueUpdate}
          </span>
          <span className="text_soft_filter_version">Версии: </span>{" "}
          <input
            value={valueVersion}
            onChange={hendlerValueVersion}
            className="inp_soft_filter_version"
            type="text"
          />
          <ExportToExcel
            fileName={`Отчёт по версии софта ниже ${valueVersion}`}
            excel={excel}
          />
        </div>
      </div>
      <div className="lic_container_header">
        <span className="rep_lic_list_name tabs_lgt_grey_border cr_p text_table_v3">
          Компания
        </span>
        <span className="rep_list_inn tabs_lgt_grey_border cr_p text_table_v3 ">
          ИНН
        </span>

        <span className="rep_list_inn tabs_lgt_grey_border cr_p text_table_v3">
          {window.innerWidth > 1100 ? "Кол-во" : "Шт"}
        </span>
      </div>
      {!ReportData.flagVersion ? (
        <ListVersionReport
          moveCompany={moveCompany}
          valueVersion={valueVersion}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
});
