import React, { useEffect, useMemo, useState } from "react";
// import PageByPageNav from "../components/PageByPageNav";
import ListCompany from "../components/company/ListCompany";
import NewCompany from "../components/company/NewCompany";
import Loader from "../components/Loader";
import ApiData from "../api/ApiData";
import { observer } from "mobx-react-lite";
import Memory from "../api/Memory";
import { Navigate } from "react-router-dom";
import RolesState from "../api/RolesState";

const Company = observer(() => {
  // Добавить новую организацию
  const [visibleAddWindows, setVisibleAddWindows] = useState(false);
  const companyAddress = "новой компании";

  const [search, setSearch] = useState("");

  const searchCompany = useMemo(() => {
    ApiData.filterDatas(search);
  }, [search]);

  // Получить список компаний mobx
  function getDataFunc() {
    ApiData.getDataCompanys();
  }
  useEffect(() => {
    Memory.funcSelectDayPeriodFlag(false);
    getDataFunc();
  }, []);

  const onClickAddNew = () => {
    !visibleAddWindows
      ? setVisibleAddWindows(true)
      : setVisibleAddWindows(false);
  };

  const ClosedContainerNewCard = () => {
    setVisibleAddWindows(false);
    ApiData.updateFlagCreateCompany(false);
  };

  const cancelAdd = () => {
    setVisibleAddWindows(false);
  };

  if (RolesState.companys) {
    return (
      <div className="main-page">
        {!ApiData.isLoading && ApiData.datas !== undefined ? (
          <>
            <ListCompany
              value={search}
              setSearch={setSearch}
              companys={ApiData.datas}
              onClickAddNew={onClickAddNew}
            />

            {RolesState.company_add ? (
              <>
                {" "}
                {visibleAddWindows ? (
                  <NewCompany
                    onClickCancel={cancelAdd}
                    ClosedContainerNewCard={ClosedContainerNewCard}
                    companyAddress={companyAddress}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    );
  } else {
    return <Navigate to="/norights" />;
  }
});

export { Company };
