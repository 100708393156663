import React, { useState } from "react";
import ApiData from "../../api/ApiData";
import useValidationText from "../../hooks/useValidationText";
import useValidationPhone from "../../hooks/useValidationPhone";
import ButtonFunctionalVer2 from "../UI/ButtonFunctionalVer2";
import { InputEditVer1 } from "../UI/InputEditVer1";
import Media from "../../api/Media";

export const Registration = ({ setRegUser }) => {
  // Переменные формы
  const [password, setPassword] = useState("");
  const [repeatPassword, setrepeatPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // Хуки
  const validPhone = useValidationPhone(phone);
  const validFirstName = useValidationText(firstName);
  const validLastName = useValidationText(lastName);
  const validFatherName = useValidationText(fatherName);

  const Registration = (e) => {
    e.preventDefault();
    if (
      repeatPassword === "" ||
      password === "" ||
      firstName === "" ||
      lastName === "" ||
      fatherName === "" ||
      email === "" ||
      phone === ""
    ) {
      return alert("Не все поля заполнены");
    }
    if (!email.match(/\w*[@]\w{2,8}[.]\w{2,4}/u)) {
      return alert("Неверный формат email");
    }

    if (password !== repeatPassword) {
      return alert("Пароли не совпадают");
    }
    if (password.length < 6) {
      return alert("Слишком короткий пароль. Минимум 6 символов");
    }

    ApiData.getRegistration(
      password,
      firstName,
      lastName,
      fatherName,
      email,
      phone
    );
    setRegUser(false);
  };

  return (
    <div className="posi-abs">
      {/* Форма регистрации */}
      <form className="reg_form">
        <h1 className="card-title margin-title">
          Регистрация нового сотрудника
        </h1>
        <ul className="col-ul-new-card">
          <li>e-mail: </li>
          <li>
            <InputEditVer1
              placeholder="e-mail будет являться логином"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: !Media.mobile ? "50%" : '100%', marginBottom: "15px" }}
            />
          </li>
          <li>Фамилия: </li>
          <li>
            <InputEditVer1
              placeholder="Введите фамилию"
              value={validLastName}
              onChange={(e) => setLastName(e.target.value)}
              style={{ width: !Media.mobile ? "50%" : '100%', marginBottom: "15px" }}
            />
          </li>
          <li>Имя: </li>
          <li>
            <InputEditVer1
              placeholder="Введите имя"
              value={validFirstName}
              onChange={(e) => setFirstName(e.target.value)}
              style={{ width: !Media.mobile ? "50%" : '100%', marginBottom: "15px" }}
            />
          </li>
          <li>Отчество: </li>
          <li>
            <InputEditVer1
              placeholder="Введите отчество"
              value={validFatherName}
              onChange={(e) => setFatherName(e.target.value)}
              style={{ width: !Media.mobile ? "50%" : '100%', marginBottom: "15px" }}
            />
          </li>
          <li>Телефон: </li>
          <li>
            <InputEditVer1
              placeholder="Введите телефон"
              type="number"
              value={validPhone}
              onChange={(e) => setPhone(e.target.value)}
              style={{ width: !Media.mobile ? "50%" : '100%', marginBottom: "15px" }}
            />
          </li>
          <li>Пароль: </li>
          <li>
            <InputEditVer1
              placeholder="Придумайте пароль"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: !Media.mobile ? "50%" : '100%', marginBottom: "15px" }}
            />
          </li>
          <li>Пароль: </li>
          <li>
            <InputEditVer1
              placeholder="Повторите пароль"
              type="password"
              value={repeatPassword}
              onChange={(e) => setrepeatPassword(e.target.value)}
              style={{ width: !Media.mobile ? "50%" : '100%', marginBottom: "15px" }}
            />
          </li>
          <li>
            <ButtonFunctionalVer2
              onClick={Registration}
              style={{ marginBottom: "15px" }}
            >
              Зарегистрировать сотрудника
            </ButtonFunctionalVer2>
          </li>
          <li>
            <ButtonFunctionalVer2 onClick={() => setRegUser(false)}>
              Отмена
            </ButtonFunctionalVer2>
          </li>
        </ul>
      </form>
    </div>
  );
};
