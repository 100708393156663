import React, { useEffect, useState } from "react";
import User from "../../api/User";
import { observer } from "mobx-react-lite";
import { ListRight } from "./ListRight";
import { ButtonFunctional } from "../UI/ButtonFunctional";
import { BtnClosedVer1 } from "../UI/BtnClosedVer1";
import { EditBtn } from "../UI/EditBtn";
import { InputEditVer1 } from "../UI/InputEditVer1";
import ButtonFunctionalVer2 from "../UI/ButtonFunctionalVer2";
import RolesState from "../../api/RolesState";

export const RoleCard = observer(({ setVisibleRoleCard }) => {
  const [role, setRole] = useState(User.role);

  useEffect(() => {
    setRole(User.role);
  }, []);

  const [edit, setEdit] = useState(false);
  const [nameRole, setNameRole] = useState(User.role.role_name);

  const actionRole = async (e) => {
    if (e.name_rights === "patch_subscription") {
      await User.setPatchSubscription();
    } else if (e.name_rights === "get_soft_company_id") {
      await User.setPatchSoftAndLic();
    } else {
      await User.installRights(e);
    }
  };
  const actionEmploees = async () => {
    User.setAdminEmploees();
  };
  const applyRight = () => {
    setVisibleRoleCard(false);
  };

  const updateRoleFunc = async () => {
    await User.updateRole(nameRole);
    setEdit(false);
  };

  const actionAdminRole = async () => {
    await User.setAdminRoles();
  };

  const setAdminClientIDFunc = async () => {
    User.setAdminClientID();
  };

  return (
    <div className="posi-abs">
      <div className="container_card">
        <div className="title_sticky_role_card">
          <div className="role_card_btn_container">
            <ButtonFunctional
              style={{ marginTop: "55px" }}
              onClick={applyRight}
            >
              Сохранить
            </ButtonFunctional>
            <BtnClosedVer1
              onClick={() => setVisibleRoleCard(false)}
              style={{ width: "36px", height: "36px" }}
            />
          </div>
          <h1
            className="card-title margin-title"
            style={{ margin: "22px 0 0 0" }}
          >
            <span>Роль: </span>
            {!edit ? (
              <span className="title_sticky_role_card_name">
                {User.role.role_name}
              </span>
            ) : (
              <InputEditVer1
                value={nameRole}
                style={{ height: "29px" }}
                onChange={(e) => setNameRole(e.target.value)}
                placeholder={User.role.role_name}
              />
            )}

            {!edit ? (
              <>
                {RolesState.admin_panel ? (
                  <EditBtn
                    onClick={() => setEdit(true)}
                    text="Изменить название роли"
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <EditBtn
                onClick={() => setEdit(false)}
                text="Отменить изменение"
              />
            )}
          </h1>
          {edit ? (
            <div className="role_card_btn_container_aply">
              <ButtonFunctionalVer2 onClick={updateRoleFunc}>
                Применить
              </ButtonFunctionalVer2>
            </div>
          ) : (
            <></>
          )}
        </div>
        <ListRight
          actionAdminRole={actionAdminRole}
          actionRole={actionRole}
          actionEmploees={actionEmploees}
          setAdminClientIDFunc={setAdminClientIDFunc}
        />
      </div>
    </div>
  );
});
