import React from "react";
import ApiData from "../../api/ApiData";
import { ItemUserAdminPanel } from "./ItemUserAdminPanel";
import { observer } from "mobx-react-lite";

export const ListUserAdminPanel = observer(
  ({ actionSuperUser, actionActiveUser }) => {

    return ApiData.employees.map((user) => (
      <ItemUserAdminPanel
        actionActiveUser={actionActiveUser}
        actionSuperUser={actionSuperUser}
        key={user.id}
        user={user}
      />
    ));
  }
);
