import { makeAutoObservable, toJS } from "mobx";
import { req } from "./api.config";
import ApiData from "./ApiData";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config.json";
import RolesState from "./RolesState";

const url_server = config.url_api;

class User {
  constructor() {
    makeAutoObservable(this);
  }

  user = JSON.parse(localStorage.getItem("User"));
  user_permission = []; // Массив с ролями юзера
  all_role = []; // Все роли
  all_permission = []; // Все разрешения
  all_rights = []; // Все права
  group_rights = []; // Сгруппированые все права
  role = {}; // Выбранная роль
  role_permission = []; //Разрешение у роли
  role_permission_data = []; //Разрешение у роли
  role_permission_basic = []; //Разрешение у роли
  add_rights = []; // Массив для добавления прав
  delete_rights = []; // Массив для удаления прав
  add_rights_data = []; // Массив для добавления прав
  delete_rights_data = []; // Массив для удаления прав
  flag_permission = false; // Флаг обновления прав
  role_id = null;
  admin_user = ["post_user", "set_activ"];
  admin_role = [
    "get_all_rights",
    "get_roles_id",
    "patch_roles_id",
    "post_roles",
    "delete_roles",
    "get_rights_id",
    "post_permission",
    "delete_permission",
    "patch_permission_id",
    "get_permission_id",
    "get_all_permission",
  ];
  admin_clientid = ["patch_client_id", "delete_licensing"];
  patch_subscription = [
    "post_subscription",
    "delete_subscription",
    "patch_subscription_prol",
  ];
  // get_soft_and_lic = ["get_license_id", "get_soft_company_id"];

  // Получить список ролей
  async allRole() {
    if (localStorage.getItem("User") === null) return;
    if (RolesState.all_roles) {
      await req
        .get(`roles`)
        .then((res) => {
          this.all_role = res.data["data"]["request"];
        })
        .catch((e) => console.log(e))
        .finally(() => (ApiData.observableUser = false));
    }
  }
  //   Получить все разрешения
  allPermission = async () => {
    this.role_permission_data = [];
    await req
      .get(`permission?${9999}`)
      .then((res) => {
        this.all_permission = res.data["data"]["request"];
      })
      .catch((e) => console.log(e));
  };
  //   Получить все права
  allRights = async () => {
    if (localStorage.getItem("User") === null) return;
    if (
      this.user.permission.includes("get_all_rights") ||
      this.user.is_superuser
    ) {
      await req
        .get(`rights?${ApiData.sizeItem}`)
        .then((res) => {
          return (this.all_rights = res.data["data"]["request"]);
        })
        .catch((e) => console.log(e))
        .finally(() => this.overkillRightsAll());
    }
  };
  overkillRightsAll = async () => {
    this.all_rights.forEach((per) => {
      return (per.activ = false);
    });
  };

  //   Прочитать роль
  getRole = async (role) => {
    this.add_rights = [];
    this.role = role;
    await this.getRolePermission();
  };
  // Получить права/разрешения у роли
  getRolePermission = async () => {
    this.role_permission = [];
    await req
      .get(`permission/${this.role.id}`)
      .then((res) => {
        this.role_permission = res.data["data"].request;
      })
      .then(() => (this.flag_permission = true))
      .catch((e) => console.log(e))
      .finally(() => {
        return this.overkillRolePermission();
      });
  };

  overkillRolePermission = async () => {
    this.role_permission_data = [...this.all_rights];
    if (this.role_permission !== undefined) {
      this.role_permission_data.forEach((rights) => {
        const permission = this.role_permission.find(
          (p) => rights.name_rights === p.rights
        );
        if (permission) {
          rights.activ = true;
        }
      });
    }
    console.log(toJS(this.role_permission_data));
  };
  //   Сгрупипровать права
  groupRights = async () => {
    this.group_rights = [];
    this.all_rights.forEach((right) => {
      if (
        !this.group_rights.includes(right.category) &&
        right.category !== "Разрешения" &&
        right.category !== "Права" &&
        right.category !== "Мой профиль" &&
        right.category !== "archive" &&
        right.category !== "ПО"
      ) {
        this.group_rights.push(right.category);
      }
    });
  };
  //   Установка разрешений
  installRights = async (e) => {
    console.log(e);
    if (localStorage.getItem("User") === null) return;
    if (
      this.user.is_superuser ||
      (this.user.permission.includes("post_permission") &&
        this.user.permission.includes("delete_permission"))
    ) {
      this.role_permission_data.find((p) => {
        if (p.id === e.id) {
          if (p.activ) {
            p.activ = false;
            this.deleteRigths([e.name_rights]);
          } else {
            p.activ = true;
            this.addRights([e.name_rights]);
          }
        }
      });
    }
  };

  //   Добавить права у роли
  addRights = async (rights) => {
    try {
      const res = await req.post(`permission`, {
        id_role: this.role.id,
        name_rights: rights,
      });
      console.log(res);
    } catch (e) {
      console.error(e);
    }
  };
  deleteRigths = async (rights) => {
    // console.log({ id_role: this.role.id, name_rights: [rights] })
    const deleteRights = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url_server + "permission",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("User"))["access_token"]
        }`,
      },
      data: { id_role: this.role.id, name_rights: rights },
    };
    const res = await axios(deleteRights);
    console.log(res);
  };

  //   Добавить новыю роль
  addRole = async (name) => {
    let res;
    try {
      res = await req.post(`roles`, { role_name: name });
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(async () => {
        const res_perm = await req.post(`permission`, {
          id_role: res.data["data"].id,
          name_rights: [
            "get_my_profile",
            "patch_my_profile",
            "patch_change_password",
          ],
        });
        this.allRole();
      }, 1000);
    }
  };

  //   Установка роли юзеру
  setUserRole = async (user) => {
    console.log({
      id_user: Number(ApiData.employee.id),
      id_role: Number(user),
    });
    await req
      .patch(`permission/relationship`, {
        id_user: ApiData.employee.id,
        id_role: user,
      })
      .catch((e) => console.log(e));
  };

  //   Дать права суперюзера
  superUser = async (id, boolean) => {
    await req
      .patch(`permission/superuser/${id}`, { is_superuser: boolean })
      .then((res) => console.log(res));
    await ApiData.getDataEmployees();
  };

  //   Изменение роли
  updateRole = async (new_name) => {
    try {
      await req.patch(`roles/${this.role.id}`, { role_name: new_name });
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(async () => {
        await this.allRole();
      }, 5000);
    }
  };

  //   Перезапрос прав
  refreshRights = async () => {
    try {
      const res = await req.get(`permission/${this.role.id}`);
    } catch (e) {
      console.log(e);
    }
  };
  // Удалить роль
  deleteRole = async (e) => {
    const deleteRole = {
      method: "delete",
      maxBodyLength: Infinity,
      url: url_server + "roles",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("User"))["access_token"]
        }`,
      },
      data: { id_role: e.id },
    };
    await axios(deleteRole)
      .then((res) => console.log(res))
      .catch((e) => console.log(e))
      .finally(() => {
        setTimeout(async () => {
          await this.allRole();
        }, 500);
      });
  };

  // Активировать/деактивировать пользователя
  setActiveUser = async (boolen, id) => {
    try {
      const res = await req.patch(`user/set_active/${id}`, {
        is_activ: boolen,
      });
    } catch (e) {
      console.log(e);
    } finally {
      await ApiData.getDataEmployees();
    }
  };

  setAdminEmploees = async () => {
    try {
      let rigth = ["post_user"];
      const sub_rigths = [
        "get_all_user",
        "get_user_id",
        "update_user",
        "patch_relationship",
      ];
      const postUserID = this.role_permission_data.find(
        (p) => p.name_rights === "post_user"
      );
      console.log(postUserID);
      if (!postUserID.activ) {
        sub_rigths.forEach((sr) => {
          const subRigthID = this.role_permission_data.find(
            (sp) => sp.name_rights === sr
          );
          if (subRigthID && !subRigthID.activ) {
            rigth = [...rigth, sr];
          }
        });
        this.addRights(rigth);
        rigth.forEach((new_rigth) => {
          const newRigthID = this.role_permission_data.find(
            (sp) => sp.name_rights === new_rigth
          );
          if (newRigthID) {
            newRigthID.activ = true;
          }
        });
      } else {
        this.deleteRigths(["post_user"]);
        postUserID.activ = false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  setAdminRoles = async () => {
    try {
      let rigth = [];
      const sub_rigths = [];
      const postRoleID = this.role_permission_data.find(
        (p) => p.name_rights === "get_roles_id"
      );
      const getAllRolesID = this.role_permission_data.find(
        (p) => p.name_rights === "get_all_roles"
      );
      if (!getAllRolesID.activ) {
        sub_rigths.push("get_all_roles");
      }
      console.log(toJS(postRoleID));
      if (!postRoleID.activ) {
        sub_rigths.forEach((sr) => {
          const subRigthID = this.role_permission_data.find(
            (sp) => sp.name_rights === sr
          );
          if (subRigthID && !subRigthID.activ) {
            rigth = [...rigth, sr];
          }
        });
        this.admin_role.forEach((sr) => {
          const subRigthID = this.role_permission_data.find(
            (sp) => sp.name_rights === sr
          );
          if (subRigthID && !subRigthID.activ) {
            rigth = [...rigth, sr];
          }
        });
        console.log(rigth);
        await this.addRights(rigth);
        rigth.forEach((new_rigth) => {
          const newRigthID = this.role_permission_data.find(
            (sp) => sp.name_rights === new_rigth
          );
          if (newRigthID) {
            newRigthID.activ = true;
          }
        });
      } else {
        await this.deleteRigths(this.admin_role);
        this.admin_role.forEach((new_rigth) => {
          const newRigthID = this.role_permission_data.find(
            (sp) => sp.name_rights === new_rigth
          );
          if (newRigthID) {
            newRigthID.activ = false;
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  setAdminClientID = async () => {
    try {
      // const right = ["patch_client_id"];
      const postClientID = this.role_permission_data.find(
        (p) => p.name_rights === "patch_client_id"
      );
      const getAllSoftClientID = this.role_permission_data.find(
        (p) => p.name_rights === "get_all_soft_client"
      );

      if (!getAllSoftClientID.activ) {
        this.admin_clientid = [...this.admin_clientid, "get_all_soft_client"];
      }
      console.log(toJS(postClientID));
      console.log("this.admin_clientid", this.admin_clientid);
      if (!postClientID.activ) {
        await this.addRights(this.admin_clientid);
        const allSoftClientID = this.role_permission_data.find(
          (p) => p.name_rights === "get_all_soft_client"
        );
        postClientID.activ = true;
        allSoftClientID.activ = true;
      } else {
        await this.deleteRigths(this.admin_clientid);
        postClientID.activ = false;
      }
    } catch (e) {
      console.log(e);
    }
  };

  setPatchSubscription = async (boolean) => {
    const right = [...this.patch_subscription, "patch_subscription"];
    try {
      const patchSubscriptionID = this.role_permission_data.find(
        (p) => p.name_rights === "patch_subscription"
      );
      console.log(toJS(patchSubscriptionID));
      if (!patchSubscriptionID.activ) {
        await this.addRights(right);
        patchSubscriptionID.activ = true;
      } else {
        await this.deleteRigths(right);
        patchSubscriptionID.activ = false;
      }
    } catch (e) {
      console.log(e);
    }
    console.log(right);
  };

  setPatchSoftAndLic = async () => {
    try {
      const patchSoftAndLicID = this.role_permission_data.find(
        (p) => p.name_rights === "get_soft_company_id"
      );
      console.log(toJS(patchSoftAndLicID));
      if (!patchSoftAndLicID.activ) {
        await this.addRights(["get_license_id", "get_soft_company_id"]);
        patchSoftAndLicID.activ = true;
      } else {
        await this.deleteRigths(["get_license_id", "get_soft_company_id"]);
        patchSoftAndLicID.activ = false;
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export default new User();
