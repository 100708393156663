// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-employee-info {
  width: 22%;
}
.employee_card_left_text {
  width: 76px;
  display: inline-block;
}
.update_pass_profile_container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 15px;
  margin: 15px 0 10px;
}
`, "",{"version":3,"sources":["webpack://./src/style/employee.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,WAAW;EACX,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,uBAAuB;EACvB,SAAS;EACT,mBAAmB;AACrB","sourcesContent":[".item-employee-info {\r\n  width: 22%;\r\n}\r\n.employee_card_left_text {\r\n  width: 76px;\r\n  display: inline-block;\r\n}\r\n.update_pass_profile_container {\r\n  display: flex;\r\n  align-items: flex-end;\r\n  justify-content: center;\r\n  gap: 15px;\r\n  margin: 15px 0 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
