import React from "react";
import ItemEmployee from "./ItemEmployee";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import RolesState from "../../api/RolesState";
import Msg from "../../api/Msg";

const ListEmploees = observer(({ users }) => {
  const hendlerEmployeeCardError = () => {
    Msg.writeMessages("У вас нет прав доступа, чтобы просматривать карточки сотрудников");
  };

  return (
    <div className="container-list">
      <div className="container-list-el">
        <div className="item-employee-info text-table">Фамилия</div>
        <div className="item-employee-info text-table">Имя</div>
        {window.innerWidth > 1100 ? (
          <div className="item-employee-info text-table">Отчество</div>
        ) : (
          <></>
        )}
        {window.innerWidth > 1100 ? (
          <div className="item-employee-info text-table">e-mail</div>
        ) : (
          <></>
        )}
        <div className="item-employee-info text-table">Телефон</div>
      </div>
      <div className="cls-list">
        {RolesState.emploee_id
          ? users.map((employee) =>
              employee.is_active ? (
                <Link key={employee.id} to={`/employees/${employee.id}`}>
                  <ItemEmployee employee={employee} />
                </Link>
              ) : (
                ""
              )
            )
          : users.map((employee) =>
              employee.is_active ? (
                <div
                  onClick={hendlerEmployeeCardError}
                  key={employee.id}
                  to={`/employees/${employee.id}`}
                >
                  <ItemEmployee employee={employee} />
                </div>
              ) : (
                ""
              )
            )}
      </div>
    </div>
  );
});

export default ListEmploees;
