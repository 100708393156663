import React from "react";
import ApiData from "../../api/ApiData";
import { ItemAllReport } from "./ItemAllReport";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Loader from "../Loader";
import { ContainerSubTitleGrey } from "../ContainerSubTitleGrey";

export const ListAllReport = observer(() => {
  const navigate = useNavigate();
  const toCompany = async (e) => {
    const this_company = await ApiData.getDataCompanyCard(
      `company/${e.company_rel.inn}`
    );
    try {
      await ApiData.saveThisCompany(this_company.inn);
    } catch (e) {
      console.log(e);
    } finally {
      navigate(`/company/${ApiData.company.inn}`);
    }
  };
  return (
    <div>
      <div className="lic_container_header">
        <span className="rep_lic_list_name tabs_lgt_grey_border cr_p text_table_v3">
          Компания
        </span>
        <span className="rep_list_inn tabs_lgt_grey_border cr_p text_table_v3 ">
          ИНН
        </span>
        <span className="rep_lic_counter tabs_lgt_grey_border cr_p text_table_v3">
          Дней
        </span>
        <span className="rep_list_valid_period_days tabs_lgt_grey_border cr_p text_table_v3">
          До
        </span>
      </div>
      {!ApiData.observableReportLic ? (
        <>
          {ApiData.subscription !== undefined ? (
            ApiData.subscription.map((lic) => (
              // <Link
              // key={lic.company_id}
              // onClick={toCompany}
              // to={`/company/${lic.company_rel.inn}`}
              // >
              <ItemAllReport
                toCompany={toCompany}
                lic={lic}
                key={lic.company_id}
              />
              // {/* </Link> */}
            ))
          ) : (
            <ContainerSubTitleGrey text="Нет подписок по заданному фильтру" />
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
});
