import React from "react";
import { ButtonFunctional } from "../../UI/ButtonFunctional";
import ApiData from "../../../api/ApiData";

export const ModalWaringClientID = ({ clientID, setModalWaringFlag }) => {
  const deleteClient = () => {
    ApiData.deleteClientID(clientID);
    setModalWaringFlag(false)
  };

  return (
    <div className="posi-abs">
      <div className="flex_center">
        {" "}
        <p className="modal-text-admin-panel">
          Вы уверены что хотите отвязать софт{" "}
          <span className="color-mast-blue-1">
            {clientID.software_client_rel.name}
          </span>{" "}
          с client-id -{" "}
          <span className="color-mast-blue-1">{clientID.client_id}</span> от
          организации{" "}
          <span className="color-mast-blue-1">
            {clientID.company_rel.name_company}
          </span>
        </p>
        <div className="btn-container-new-company">
          <ButtonFunctional onClick={deleteClient}>
            Отвязать клиента
          </ButtonFunctional>
          <ButtonFunctional onClick={() => setModalWaringFlag(false)}>
            Отмена
          </ButtonFunctional>
        </div>
      </div>
    </div>
  );
};
