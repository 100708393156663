import { makeAutoObservable, toJS } from "mobx";
import ApiData from "./ApiData";
import Memory from "./Memory";

class funcFilter {
  license = ""; // Лицензия для активации клиента
  license_index = 0; // Лицензия для активации клиента
  base_clients_soft = [];

  constructor() {
    makeAutoObservable(this);
  }
  // Cортировка версии софта на увеличение
  sortFuncSoftVersionAB(arr) {
    arr.sort((a, b) => {
      if (a.software_version < b.software_version) {
        return -1;
      }
      if (a.software_version > b.software_version) {
        return 1;
      }
      return 0;
    });
  }
  // Cортировка версии софта на уменьшение
  sortFuncSoftVersionBA(arr) {
    arr.sort((a, b) => {
      if (a.software_version < b.software_version) {
        return 1;
      }
      if (a.software_version > b.software_version) {
        return -1;
      }
      return 0;
    });
  }
  // Cортировка по наименованию софта на увеличение
  sortFuncSoftNameAB(arr) {
    arr.sort((a, b) => {
      if (a["software_client_rel"].name < b["software_client_rel"].name) {
        return -1;
      }
      if (a["software_client_rel"].name > b["software_client_rel"].name) {
        return 1;
      }
      return 0;
    });
  }
  // Cортировка по наименованию софта на уменьшение
  sortFuncSoftNameBA(arr) {
    arr.sort((a, b) => {
      if (a["software_client_rel"].name < b["software_client_rel"].name) {
        return 1;
      }
      if (a["software_client_rel"].name > b["software_client_rel"].name) {
        return -1;
      }
      return 0;
    });
  }
  // // Cортировка по дате сгорания лицензии на увеличение
  // sortFuncActivDataAB(arr) {
  //   arr.sort((a, b) => {
  //     if (a.activ_data < b.activ_data) {
  //       return -1;
  //     }
  //     if (a.activ_data > b.activ_data) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // }
  // // Cортировка по дате сгорания лицензии на уменьшение
  // sortFuncActivDataBA(arr) {
  //   arr.sort((a, b) => {
  //     if (a.activ_data < b.activ_data) {
  //       return 1;
  //     }
  //     if (a.activ_data > b.activ_data) {
  //       return -1;
  //     }
  //     return 0;
  //   });
  // }
  // Cортировка cроку жизни лицензии (в днях) лицензии на увеличение
  sortFuncvalidPeriodDaysAB(arr) {
    arr.sort((a, b) => {
      if (a.valid_period_days < b.valid_period_days) {
        return -1;
      }
      if (a.valid_period_days > b.valid_period_days) {
        return 1;
      }
      return 0;
    });
  }
  // Cортировка cроку жизни лицензии (в днях) лицензии на увеличение
  sortFuncvalidPeriodDaysBA(arr) {
    arr.sort((a, b) => {
      if (a.valid_period_days < b.valid_period_days) {
        return 1;
      }
      if (a.valid_period_days > b.valid_period_days) {
        return -1;
      }
      return 0;
    });
  }
  //   Сортировка последняя сессия увеличение
  sortFuncLastSessionAB(arr) {
    arr.sort((a, b) => {
      let aa = new Date(a.last_session).getTime();
      let bb = new Date(b.last_session).getTime();
      return aa - bb;
    });
  }
  //   Сортировка последняя сессия уменьшение
  sortFuncLastSessionBA(arr) {
    arr.sort((a, b) => {
      let aa = new Date(a.last_session).getTime();
      let bb = new Date(b.last_session).getTime();
      return bb - aa;
    });
  }
  // Cортировка по площадкам
  async sortSoftProduction(id) {
    ApiData.observableSoft = true;
    // if (ApiData.clients_soft_data !== undefined) {
    ApiData.clients_soft = ApiData.clients_soft_data.filter(
      (cs) => cs["production_rel"] !== null && cs["production_rel"].id === id
    );
    // }
    Memory.funcFilterByProduction(ApiData.this_production.name_production);
    ApiData.observableSoft = false;
  }
  // Cортировка по клиентам не привязанных к площадкам
  sortSoftProductionNull() {
    try {
      this.showAllClients();
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        if (ApiData.clients_soft_data !== undefined) {
          ApiData.clients_soft = ApiData.clients_soft.filter(
            (cs) => cs["production_rel"] === null
          );
        }
      }, 200);
    }
  }
  // Отобразить всех клиентов
  showAllClients() {
    ApiData.getClientsLicensing();
    Memory.funcFilterByProduction(null);
  }
  // Выбранная лицензия
  selectlicense() {
    if (ApiData.licensing_free.length) {
      this.license = ApiData.licensing_free[this.license_index];
    }
    Memory.funcFilterByProduction(null);
  }

  // Фильтр по id client card company
  searchIdClient(search) {
    if (search) {
      return (ApiData.clients_soft = ApiData.clients_soft.filter((clid) =>
        toJS(clid).client_id.toLowerCase().includes(search.toLowerCase())
      ));
    }
    return (ApiData.clients_soft = ApiData.clients_soft_data);
  }
  // Фильтр по id client весь софт
  searchIdClientAllSoft(search) {
    if (search) {
      return (ApiData.soft_all = ApiData.soft_all.filter((clid) =>
        toJS(clid).client_id.toLowerCase().includes(search.toLowerCase())
      ));
    }
    return (ApiData.soft_all = ApiData.soft_all_data);
  }

  // Функция выбора лицензии из списка

  selectFreeLic(e) {
    this.license = ApiData.licensing_free.find(
      (l) => l.id_license === e.id_license
    );
  }
  // Фильтр по лицензиям, активация которых истекает через n дней
  dayFilterLic() {
    if (sessionStorage.getItem("day_active_flag") === "true") {
      ApiData.clients_soft = ApiData.clients_soft_data.filter(
        (lic) =>
          (lic.valid_period_days < Number(Memory.day_active_lic) &&
            lic.valid_period_days !== "" &&
            lic.valid_period_days !== null) ||
          lic.valid_period_days === "Истекла" ||
          lic.valid_period_days === "Обновите"
      );
    }
  }
  // Фильтр по компаниям общий
  filterDataCompanyReport(search) {
    if (search) {
      return (ApiData.subscription = ApiData.subscription_data.filter(
        (c) =>
          toJS(c)
            .company_rel.inn.toLowerCase()
            .includes(search.toLowerCase()) ||
          c.company_rel.name_company
            .toLowerCase()
            .includes(search.toLowerCase())
      ));
    }
    return ApiData.subscription_data;
  }
  // Фильтр по версии софта в компании
  filterSoftVersionCompany = (version) => {
    if (version) {
      const verReg = version.replace(/\./gm, "");
      console.log("verReg", verReg);
      return (ApiData.clients_soft = ApiData.clients_soft_data.filter(
        (s) =>
          Number(
            s.software_version.replace(/\./gm, "").slice(0, verReg.length)
          ) <= Number(verReg)
      ));
    }
    return (ApiData.clients_soft = ApiData.clients_soft_data);
  };
}

export default new funcFilter();
