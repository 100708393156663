import { observer } from "mobx-react-lite";
import React from "react";

export const ItemVersionReport = observer((props) => {
  return (
    <div
      onClick={() => props.moveCompany(props.ver)}
      className="lic_container_item"
    >
      <span className="rep_lic_list_name padding_7_5 tabs_lgt_grey_border">
        {props.ver.name_company}
      </span>
      <span className="rep_list_inn padding_7_5 tabs_lgt_grey_border">
        {props.ver.inn}
      </span>
      <span className="rep_list_inn padding_7_5 tabs_lgt_grey_border">
        {props.ver.count}
      </span>
    </div>
  );
});
