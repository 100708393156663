import React, { useState } from "react";
import ApiData from "../../api/ApiData";
import { observer } from "mobx-react-lite";
import { InputEditVer1 } from "../UI/InputEditVer1";
import ButtonFunctionalVer2 from "../UI/ButtonFunctionalVer2";
import { EditBtn } from "../UI/EditBtn";
import { BtnClosedVer1 } from "../UI/BtnClosedVer1";
import RolesState from "../../api/RolesState";

export const ThisProduction = observer(({ setFlagThisModalProduction }) => {
  const [flagEdit, setFlagEdit] = useState(false);
  // Переменные площадки
  const [nameProduction, setNameProduction] = useState(
    ApiData.this_production.name_production
  );
  const [productionAddress, setProductionAddress] = useState(
    ApiData.this_production.address_production
  );

  const updateProduction = () => {
    if (nameProduction === "" || productionAddress === "") {
      return ApiData.writeMessage("Не все поля заполнены");
    }

    const dataProduction = {
      company_id: Number(ApiData.this_production.company_id),
      name_production: nameProduction,
      address_production: productionAddress,
    };
    console.log(dataProduction);
    ApiData.updateProduction(dataProduction);
    setFlagEdit(false);
  };

  return (
    <div className="posi-abs-mini">
      <div className="title_container_production">
        <span className="li_title">Площадка</span>
        <div className="title_production_btn_container">
          {RolesState.patch_production ? (
            <EditBtn onClick={() => setFlagEdit(true)} />
          ) : (
            <></>
          )}

          <BtnClosedVer1 onClick={() => setFlagThisModalProduction(false)} />
        </div>
      </div>
      <div className="mrg-top-12 ">
        <ul className="">
          {!flagEdit ? (
            <>
              <li className="card-text-item_v2 mrg-btm-10">{nameProduction}</li>
              <li className="card-text-item_v2">
                Адрес: {productionAddress}
              </li>{" "}
            </>
          ) : (
            <>
              <li>Название</li>
              <li>
                {" "}
                <InputEditVer1
                  value={nameProduction}
                  onChange={(e) => setNameProduction(e.target.value)}
                  placeholder="Введите название площадки"
                  style={{ width: "100%" }}
                />
              </li>
              <li className="mrg-top-12 ">Адрес</li>
              <li>
                {" "}
                <InputEditVer1
                  value={productionAddress}
                  onChange={(e) => setProductionAddress(e.target.value)}
                  placeholder="Введите адрес площадки"
                  style={{ width: "100%" }}
                />
              </li>
            </>
          )}
        </ul>
      </div>
      {flagEdit ? (
        <div className="btn-container-new-company mrg-top-50">
          <ButtonFunctionalVer2 onClick={updateProduction}>
            Сохранить
          </ButtonFunctionalVer2>
          <ButtonFunctionalVer2 onClick={() => setFlagEdit(false)}>
            Отменить
          </ButtonFunctionalVer2>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
});
