import React from "react";

export const BtnFuncVer3 = ({ children, ...props }) => {
  return (
    <button
      className="btn_func_container_v3"
      style={props.style}
      onClick={props.onClick}
    >
      <span>{children}</span>
    </button>
  );
};
