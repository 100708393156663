import React, { useState } from "react";
import { GenerationSubscription } from "./GenerationSubscription";
import { BtnFuncV4 } from "../UI/BtnFuncV4";
import ApiData from "../../api/ApiData";
import { observer } from "mobx-react-lite";
import { MyBtnCheckActive } from "../UI/MyBtnCheckActive";
import RolesState from "../../api/RolesState";

export const SubscriptionComponentCard = observer(() => {
  const [newFlag, setNewFlag] = useState(false);
  const [prolongation, setProlongation] = useState(false);

  const openNew = () => {
    setNewFlag(true);
    setProlongation(false);
  };
  const openProlongation = () => {
    setNewFlag(false);
    setProlongation(true);
  };

  if (ApiData.company.subscription_rel) {
    return (
      <div className="company_card_block_lic_text flx-sb ">
        {ApiData.company.subscription_rel.length ? (
          <>
            <label className="company_card_block_subscription_label">
              {RolesState.patch_subscription ? (
                <MyBtnCheckActive
                  onClick={() => ApiData.deleteSubscription()}
                  active={true}
                />
              ) : (
                <></>
              )}
              Подписка:
              <span style={{ color: `var(--main-blue-1)` }}>
                {" "}
                активна
              </span> до: {ApiData.company.subscription_rel[0].validity_date}
            </label>
            <BtnFuncV4 text="Продлить подписку" onClick={openProlongation} />
          </>
        ) : (
          <>
            <label className="company_card_block_subscription_label">
              {RolesState.patch_subscription ? (
                <MyBtnCheckActive onClick={openNew} active={false} />
              ) : (
                <></>
              )}
              Подписка:
              <span style={{ color: `var(--error)` }}> не активна</span>
            </label>
          </>
        )}
        {newFlag ? (
          <GenerationSubscription
            newFlag={newFlag}
            flag={setNewFlag}
            title="Активация лицензии"
            func={ApiData.postSubscription}
          />
        ) : (
          <></>
        )}
        {prolongation ? (
          <GenerationSubscription
            func={ApiData.prolongationSubscription}
            title="Продление лицензии"
            flag={setProlongation}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
});
