// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rep_lic_list_name {
  align-items: center;
  width: 60%;
  font-size: 14px;
  min-width: 200px;
}
.rep_list_inn {
  align-items: center;
  width: 20%;
  font-size: 14px;
}
.rep_lic_counter {
  align-items: center;
  width: 5%;
  font-size: 14px;
}
.rep_list_valid_period_days {
  align-items: center;
  width: 15%;
  font-size: 14px;
}
.container_bar_all_report_lic {
  margin: 2px 1px;
  padding: 6px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.title_report {
  font-weight: 500;
  font-size: 24px;
  margin: 20px 0;
}
`, "",{"version":3,"sources":["webpack://./src/style/report.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,mBAAmB;EACnB,UAAU;EACV,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,SAAS;EACT,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,UAAU;EACV,eAAe;AACjB;AACA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB","sourcesContent":[".rep_lic_list_name {\r\n  align-items: center;\r\n  width: 60%;\r\n  font-size: 14px;\r\n  min-width: 200px;\r\n}\r\n.rep_list_inn {\r\n  align-items: center;\r\n  width: 20%;\r\n  font-size: 14px;\r\n}\r\n.rep_lic_counter {\r\n  align-items: center;\r\n  width: 5%;\r\n  font-size: 14px;\r\n}\r\n.rep_list_valid_period_days {\r\n  align-items: center;\r\n  width: 15%;\r\n  font-size: 14px;\r\n}\r\n.container_bar_all_report_lic {\r\n  margin: 2px 1px;\r\n  padding: 6px 20px;\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: flex-end;\r\n}\r\n.title_report {\r\n  font-weight: 500;\r\n  font-size: 24px;\r\n  margin: 20px 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
