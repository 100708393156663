import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import ApiData from "../../api/ApiData";
import { ButtonFunctional } from "../UI/ButtonFunctional";
import FilterData from "../../api/FilterData";
import AlertData from "../../api/AlertData";

export const RenewalLicensing = observer(
  ({ setVisibleRenewalBlock, license }) => {
    const [period, setPeriod] = useState(36500);
    const [period3Cls, setPeriod3Cls] = useState("btn_deactive_period");
    const [period7Cls, setPeriod7Cls] = useState("btn_deactive_period");
    const [period14Cls, setPeriod14Cls] = useState("btn_deactive_period");
    const [period30Cls, setPeriod30Cls] = useState("btn_deactive_period");
    const [period90Cls, setPeriod90Cls] = useState("btn_deactive_period");
    const [period180Cls, setPeriod180Cls] = useState("btn_deactive_period");
    const [period365Cls, setPeriod365Cls] = useState("btn_deactive_period");
    const [periodInfinityCls, setPeriodInfinityCls] =
      useState("btn_active_period");

    const handlerPeriod = (e) => {
      setPeriod3Cls("btn_deactive_period");
      setPeriod7Cls("btn_deactive_period");
      setPeriod14Cls("btn_deactive_period");
      setPeriod30Cls("btn_deactive_period");
      setPeriod90Cls("btn_deactive_period");
      setPeriod180Cls("btn_deactive_period");
      setPeriod365Cls("btn_deactive_period");
      setPeriodInfinityCls("btn_deactive_period");

      if (Number(e.target.textContent) === 3) {
        setPeriod3Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 7) {
        setPeriod7Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 14) {
        setPeriod14Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 30) {
        setPeriod30Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 90) {
        setPeriod90Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }

      if (Number(e.target.textContent) === 180) {
        setPeriod180Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (Number(e.target.textContent) === 365) {
        setPeriod365Cls("btn_active_period");
        setPeriod(e.target.textContent);
      }
      if (e.target.textContent === "Бессрочно") {
        setPeriodInfinityCls("btn_active_period");
        setPeriod(36500);
      }
    };

    const renewalLicensing = () => {
      console.log("license", license);
      ApiData.renewalLic(license, period);
      setVisibleRenewalBlock(false);
      AlertData.calcError();
    };

    return (
      <div className="posi-abs">
        <div className="flex_center">
          <div className="generation_lic_container">
            <div className="generation_lic_period_block">
              <div className="tabs_lgt_grey_border generation_lic_min_block_left">
                <span>Выберите срок продления лицензии: </span>
                <div className="generation_lic_btn_period">
                  <span className={period3Cls} onClick={handlerPeriod}>
                    3
                  </span>
                  <span className={period7Cls} onClick={handlerPeriod}>
                    7
                  </span>
                  <span className={period14Cls} onClick={handlerPeriod}>
                    14
                  </span>
                  <span className={period30Cls} onClick={handlerPeriod}>
                    30
                  </span>
                  <span className={period90Cls} onClick={handlerPeriod}>
                    90
                  </span>
                  <span className={period180Cls} onClick={handlerPeriod}>
                    180
                  </span>
                  <span className={period365Cls} onClick={handlerPeriod}>
                    365
                  </span>
                  <span className={periodInfinityCls} onClick={handlerPeriod}>
                    Бессрочно
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-container-new-company">
            <ButtonFunctional onClick={renewalLicensing}>
              Продлить лицензию
            </ButtonFunctional>
            <ButtonFunctional onClick={() => setVisibleRenewalBlock(false)}>
              Отмена
            </ButtonFunctional>
          </div>
        </div>
      </div>
    );
  }
);
