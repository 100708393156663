import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/Header";
import Memory from "../api/Memory";
import { useEffect, useState } from "react";
import ApiData from "../api/ApiData";
import User from "../api/User";
import { toJS } from "mobx";
import Media from "../api/Media";
import { observer } from "mobx-react-lite";
import { MessagesContainer } from "../components/messages/MessagesContainer";
// import { useAuth } from "../hoc/AuthProvaider";

const LayOut = observer(() => {
  const location = useLocation();

  const beginLoading = async () => {
    if (ApiData.isAuth) {
      await ApiData.getDataCompanys();
      // await ApiData.getAllLicensings();
    }
  };

  useEffect(() => {
    beginLoading();
  }, []);

  return (
    <div className="layout-container">
      {location.pathname !== "/login" ||
      localStorage.getItem("User") !== null ||
      location.pathname !== "/" ? (
        <Header />
      ) : (
        <></>
      )}

      <main className="container">
        <MessagesContainer />
        <Outlet />
      </main>
    </div>
  );
});

export { LayOut };
