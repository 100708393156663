import React from "react";
import ItemProduction from "./ItemProduction";
import ApiData from "../../api/ApiData";
import { observer } from "mobx-react-lite";

const LisrProduction = observer(({ getSoftProductionFunc, moveSoftProductionFunc }) => {
  return (
    <div className="container-list-this">
      {ApiData.productions.map((itemproduction) => (
        <ItemProduction
          key={itemproduction.id}
          itemproduction={itemproduction}
          getSoftProductionFunc={getSoftProductionFunc}
          moveSoftProductionFunc={moveSoftProductionFunc}
        />
      ))}
    </div>
  );
});

export default LisrProduction;
