import React, { useState } from "react";
import { BtnSizeVer1 } from "../UI/button/BtnSizeVer1";

export const ItemChartComponent = ({ children, name, defect, defectDetail }) => {
  const [clsChartContainer, setClsChartContainer] = useState(
    "chart_container_item"
  );

  const handlerClsChart = () => {
    if (clsChartContainer === "chart_container_item") {
      setClsChartContainer("chart_container_item-active");
    } else {
      setClsChartContainer("chart_container_item");
    }
  };

  return (
    <div className={clsChartContainer}>
      <div className="chart_container_nav">
        <p>{name}</p>
        <label className="chart_container_label">
          {defect ? <button onClick={defectDetail} className="chart_container_label-btn_defect">Детали: брак</button> : <></>}
          <BtnSizeVer1 onClick={handlerClsChart} />
        </label>
      </div>
      {children}
    </div>
  );
};
