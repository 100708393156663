import React, { useState } from "react";
import { Link } from "react-router-dom";
import useValidationPhone from "../../hooks/useValidationPhone";
import ModalLoader from "./ModalLoader";
import { ApiINNCompany } from "../../api/ApiINNCompany";
import ApiData from "../../api/ApiData";
import { observer } from "mobx-react-lite";
import { ButtonFunctional } from "../UI/ButtonFunctional";
import { InputEditVer1 } from "../UI/InputEditVer1";
import Msg from "../../api/Msg";
import { useValidationNumber } from "../../hooks/useValidationNumber";

const NewCompany = observer(({ ClosedContainerNewCard, onClickCancel }) => {
  //   Переменные компании
  const [nameCompany, setNameCompany] = useState("");
  const [kpp, setKpp] = useState("");
  const validKpp = useValidationNumber(kpp);
  const [inn, setInn] = useState("");
  const validInn = useValidationNumber(inn);
  const [companyAddress, setCompanyAddress] = useState("");
  const [phone, setPhone] = useState("");
  const validPhone = useValidationPhone(phone);
  const [contactRepresentative, setContactRepresentative] = useState("");
  const [descriptions, setDescriptions] = useState("");

  const [loadingCompanyINN, setLoadingCompanyINN] = useState(false);

  const dt = {
    inn: String(inn).replace(/\D/gm, ""),
    name_company: nameCompany,
    address_company: companyAddress,
    phone: String(phone).replace(/\D/gm, ""),
    contact_representative: contactRepresentative,
    number_company: 1,
  };

  const setCompany = () => {
    console.log(dt);
    if (
      nameCompany === "" ||
      (kpp === "" && inn.replace(/\D/gm, "").length === 10) ||
      inn === "" ||
      companyAddress === "" ||
      contactRepresentative === "" ||
      phone === ""
    ) {
      Msg.writeMessages("Не все поля заполнены");
    }
    if (kpp.length !== 9 && inn.length === 10) {
      return Msg.writeMessages("КПП должно быть 9 цифр");
    }
    if (
      inn.replace(/\D/gm, "").length !== 10 &&
      inn.replace(/\D/gm, "").length !== 12
    ) {
      console.log(inn.length);
      return Msg.writeMessages("ИНН должно быть 10 или 12 цифр");
    }
    if (
      kpp.replace(/\D/gm, "").length === 9 &&
      inn.replace(/\D/gm, "").length === 10
    ) {
      console.log(kpp);
      dt["kpp"] = kpp;
    }
    if (descriptions !== "") {
      dt["descriptions"] = descriptions;
    }
    console.log(dt);
    ApiData.newCompany(dt);
  };

  const removeField = () => {
    setNameCompany("");
    setKpp("");
    setInn("");
    setCompanyAddress("");
    setPhone("");
    setContactRepresentative("");
    setDescriptions("");
  };

  const addNext = () => {
    ApiData.updateFlagCreateCompany(false);
    removeField();
  };

  return (
    <div className="posi-abs">
      {loadingCompanyINN ? <ModalLoader /> : <></>}
      <h1 className="card-title margin-title">Создание новой компании</h1>
      <div className="add-item-container">
        <div className="card-flex-container">
          <ul className="col-ul-new-card">
            <li className="new_company_inn_block">
              <span>ИНН</span>{" "}
              <ApiINNCompany
                inn={validInn}
                setNameCompany={setNameCompany}
                setCompanyAddress={setCompanyAddress}
                setKpp={setKpp}
                setLoadingCompanyINN={setLoadingCompanyINN}
              />
            </li>
            <li>
              <InputEditVer1
                value={String(validInn)}
                onChange={(e) => setInn(e.target.value)}
                placeholder="Введите ИНН"
                style={{ width: "50%" }}
              />
            </li>

            <li className="mrg-top-12">Наименование</li>
            <li>
              {" "}
              <InputEditVer1
                value={nameCompany}
                onChange={(e) => setNameCompany(e.target.value)}
                placeholder="Введите наименование компании"
                style={{ width: "100%" }}
              />
            </li>

            <li className="mrg-top-12">Адрес</li>
            <li>
              {" "}
              <InputEditVer1
                value={companyAddress}
                onChange={(e) => setCompanyAddress(e.target.value)}
                placeholder="Введите адрес компании"
                style={{ width: "100%" }}
              />
            </li>

            <li className="mrg-top-12">Контактное лицо</li>
            <li>
              {" "}
              <InputEditVer1
                value={contactRepresentative}
                onChange={(e) => setContactRepresentative(e.target.value)}
                placeholder="Введите имя ответственного сотрудника"
                style={{ width: "100%" }}
              />
            </li>

            <li className="mrg-top-12">КПП</li>
            <li>
              {" "}
              <InputEditVer1
                value={validKpp}
                onChange={(e) => setKpp(e.target.value)}
                placeholder="Введите КПП компании"
                style={{ width: "100%" }}
              />
            </li>

            <li className="mrg-top-12">Телефон</li>
            <li>
              {" "}
              <InputEditVer1
                value={validPhone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Введите контактный телефон сотрудника"
                style={{ width: "100%" }}
              />
            </li>
            <li className="mrg-top-12">Описание</li>
            <li>
              {" "}
              <textarea
                className="comment"
                placeholder="Введите дополнительное описание или комментарии"
                value={descriptions}
                onChange={(e) => setDescriptions(e.target.value)}
              ></textarea>
            </li>
          </ul>
        </div>
      </div>
      <div className="btn-container-new-company">
        {!ApiData.create_company_pass ? (
          <>
            <ButtonFunctional onClick={setCompany}>Сохранить</ButtonFunctional>
            <ButtonFunctional onClick={onClickCancel}>Отмена</ButtonFunctional>
          </>
        ) : (
          <>
            <ButtonFunctional onClick={addNext} className="add-new-card">
              Добавить следующую
            </ButtonFunctional>
            <Link to={`/company/${inn}`}>
              <ButtonFunctional className="add-new-card">
                Перейти к текущей
              </ButtonFunctional>
            </Link>
            <ButtonFunctional
              onClick={ClosedContainerNewCard}
              className="add-new-card"
            >
              Закрыть
            </ButtonFunctional>
          </>
        )}
      </div>
    </div>
  );
});

export default NewCompany;
