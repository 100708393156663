import React, { useState } from "react";
import ApiData from "../../api/ApiData";
import { MyBtnCheckActive } from "../UI/MyBtnCheckActive";
import { InputEditVer1 } from "../UI/InputEditVer1";
import { BtnFuncVer3 } from "../UI/BtnFuncVer3";
import User from "../../api/User";
import ButtonFunctionalVer2 from "../UI/ButtonFunctionalVer2";

export const NumberCompany = () => {
  const [valueNumber, setValueNumber] = useState(
    ApiData.company.number_company
  );
  const [upDateNumberBlock, setUpDateNumberBlock] = useState(false);
  const [showEditNumber, setShowEditNumber] = useState(false);

  const updateUrNumberFunc = () => {
    if (valueNumber <= 0) {
      return alert("Номер компании не может быть ниже нуля");
    }
    if (valueNumber > 1) {
      ApiData.updateUrNumber(valueNumber);
      setUpDateNumberBlock(false);
    } else {
      ApiData.updateUrNumber(1);
      setUpDateNumberBlock(false);
    }
  };

  return (
    <div>

      {ApiData.company.number_company === 1 ? (
        <>
          {!upDateNumberBlock ? (
            <div className="my_btn_check_active_container_row">
              {User.user.permission.includes("patch_company") ||
              User.user.is_superuser ? (
                <MyBtnCheckActive
                  active={false}
                  onClick={() => setUpDateNumberBlock(true)}
                />
              ) : (
                <></>
              )}
              <span className="my_btn_check_active_span_1">
                Работа с несколькими юридическими лицами выключена.
              </span>
            </div>
          ) : (
            <div className="my_btn_check_active_container_row">
              <span className="my_btn_check_active_span_1">Укажите количество компаний с которыми работает организация:</span>
              <input
                className="input_nmb"
                type="number"
                value={valueNumber}
                onChange={(e) => setValueNumber(e.target.value)}
                placeholder="0"
                min="1"
              />
              <ButtonFunctionalVer2 onClick={updateUrNumberFunc}>
                Подтвердить
              </ButtonFunctionalVer2>
              <ButtonFunctionalVer2 onClick={() => setUpDateNumberBlock(false)}>
                Отмена
              </ButtonFunctionalVer2>
            </div>
          )}
        </>
      ) : (
        <div className="my_btn_check_active_container_column">
          {!showEditNumber ? (
            <div className="company_card_block_lic_text mrg-top-12">
              Компания работает с {ApiData.company.number_company} юридическими
              лицами
              {User.user.permission.includes("patch_company") ||
              User.user.is_superuser ? (
                <BtnFuncVer3 onClick={() => setShowEditNumber(true)}>
                  изменить
                </BtnFuncVer3>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className="company_card_block_lic_text mrg-top-12">
              Введите новое количество{" "}
              <InputEditVer1
                value={valueNumber}
                onChange={(e) => setValueNumber(e.target.value)}
                style={{ width: "45px" }}
                type="number"
              />
              <BtnFuncVer3 onClick={updateUrNumberFunc}>применить</BtnFuncVer3>
              <BtnFuncVer3 onClick={() => setShowEditNumber(false)}>
                отменить
              </BtnFuncVer3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
