import React, { useEffect, useState } from "react";
import { ListUserAdminPanel } from "./ListUserAdminPanel";
import { observer } from "mobx-react-lite";
import User from "../../api/User";

import { ButtonFunctional } from "../UI/ButtonFunctional";
import { Registration } from "./Registration";

export const UsersAdminPanel = observer(() => {
  const [regUser, setRegUser] = useState(false);

  useEffect(() => {
    User.allRole();
  }, []);

  const actionDisableUser = async (e) => {
    console.log(e);
  };

  const actionSuperUser = async (e) => {
    console.log(e);
    if (e.is_superuser) {
      await User.superUser(e.id, false);
    } else {
      await User.superUser(e.id, true);
    }
  };

  const actionActiveUser = async (e) => {
    console.log(e);
    if (e.is_active) {
      User.setActiveUser(false, e.id);
    } else {
      User.setActiveUser(true, e.id);
    }
  };

  return (
    <div className="container_1366">
      <div className="flx-container-btn-right-position ">
        {User.user.is_superuser ||
        User.user.permission.includes("post_user") ? (
          <>
            <ButtonFunctional
              className="login-btn-login"
              onClick={() => setRegUser(true)}
            >
              Добавить сотрудника
            </ButtonFunctional>
          </>
        ) : (
          <></>
        )}
      </div>
      {regUser ? <Registration setRegUser={setRegUser} /> : <></>}
      <div className="container-item-el">
        <label className="container_title_user-item-el">
          <span className="item_user_admin_name item-cosmetic">Фамилия</span>
          <span className="item_user_admin_name item-cosmetic">Имя</span>
          <span className="item_user_admin_email item-cosmetic">
            email/логин
          </span>
          <span className="item_user_admin_email item-cosmetic">Роль</span>
        </label>
        <span className="item_user_admin_10 item-cosmetic">Активен</span>
        {User.user.is_superuser ? (
          <span className="item_user_admin_10 item-cosmetic">Суперюзер</span>
        ) : (
          <></>
        )}
      </div>
      <ListUserAdminPanel
        actionSuperUser={actionSuperUser}
        actionDisableUser={actionDisableUser}
        actionActiveUser={actionActiveUser}
      />
    </div>
  );
});
