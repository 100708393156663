import React, { useState } from "react";
import iconEdit from "../../img/icons/dmc_edit.png";

export const EditBtn = (props) => {
  const [text, setText] = useState(props.text);
  return (
    <label onClick={props.onClick} className="container-edit-btn">
      <img className="icon-edit" src={iconEdit} alt="" />
      {text !== undefined ? (
        <span className="edit-btn-text">{props.text}</span>
      ) : (
        <></>
      )}      
    </label>
  );
};
