import { useEffect, useState } from "react";

const useValidationText = (value) => {
  const [valueValid, setValueValid] = useState("");
  useEffect(() => {
    if (value.match(/^[a-zA-Zа-яА-ЯыЫ-\sёЁ]*$/g)) {
      setValueValid(value);
    }
  }, [value]);
  return valueValid;
};

export default useValidationText;
