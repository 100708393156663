import React from "react";
import User from "../../api/User";

export const ListOptionRole = () => {


  const selectRole = (e) => {
    if (e.target.selectedIndex !== 0) {
      console.log(e);
      User.setUserRole(e.target.value);
    }
  };

  return (
    <select onChange={selectRole}>
      <option>Выберите роль</option>
      {User.all_role !== undefined ? (
        User.all_role.map((role) => (
          <option role={role} key={role.id} value={role.id}>
            {role.role_name}
          </option>
        ))
      ) : (
        <></>
      )}
    </select>
  );
};
