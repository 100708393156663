import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import ApiData from "../../api/ApiData";
import { ButtonFunctional } from "../UI/ButtonFunctional";
import { BtnFuncV4 } from "../UI/BtnFuncV4";
import Msg from "../../api/Msg";

export const GenerationLicense = observer(() => {
  const [visibleGenerationBlock, setVisibleGenerationBlock] = useState(false);
  const [count, setCount] = useState(1);
  const [period, setPeriod] = useState(36500);
  const [description, setDescription] = useState("");
  const [periodInfinityCls, setPeriodInfinityCls] =
    useState("btn_active_period");
  const [liteVersion, setLiteVersion] = useState(false);
  const [versionProCls, setVersionProCls] = useState("btn_active_period");
  const [versionLiteCls, setVersionLiteCls] = useState("btn_deactive_period");

  const handlerCount = (e) => {
    if (e.target.value < 0) {
      return setCount(0);
    }
    return setCount(e.target.value);
  };
  const handlerPeriod = (e) => {
    console.log(e.target.textContent);
    setPeriodInfinityCls("btn_deactive_period");

    if (e.target.textContent === "Бессрочно") {
      setPeriodInfinityCls("btn_active_period");
      setPeriod(36500);
    }
  };

  const handlerVersion = (e) => {
    setVersionProCls("btn_deactive_period");
    setVersionLiteCls("btn_deactive_period");
    if (e.target.textContent === "Pro") {
      setLiteVersion(false);
      setVersionProCls("btn_active_period");
    }
    if (e.target.textContent === "Lite") {
      setLiteVersion(true);
      setVersionLiteCls("btn_active_period");
    }
  };
  const generationLicense = () => {
    console.log(count, period, liteVersion, description);
    if (description) {
      ApiData.generationLicense(count, period, liteVersion, description);
      setVisibleGenerationBlock(false);
      setDescription("");
    } else {
      Msg.writeMessages("Поле комментария не заполнено");
    }
  };

  return (
    <>
      <BtnFuncV4
        onClick={() => setVisibleGenerationBlock(true)}
        text="Добавить новую лицензию"
      />
      {visibleGenerationBlock ? (
        <div className="posi-abs">
          <div className="flex_center">
            <div className="generation_lic_container">
              <div>
                <span>Укажите количество лицензий: </span>
                <input
                  className="input_nmb"
                  onChange={handlerCount}
                  value={count}
                  type="number"
                  placeholder="1"
                  min="1"
                />
              </div>

              <div className="generation_lic_period_block">
                <div className="tabs_lgt_grey_border generation_lic_min_block_left">
                  <span>Выберите срок лицензии: </span>
                  <div className="generation_lic_btn_period">
                    <span className={periodInfinityCls} onClick={handlerPeriod}>
                      Бессрочно
                    </span>
                  </div>
                </div>
                <div className="tabs_lgt_grey_border generation_lic_min_block_right">
                  <span>Версия ПО: </span>
                  <span className={versionProCls} onClick={handlerVersion}>
                    Pro
                  </span>
                  <span className={versionLiteCls} onClick={handlerVersion}>
                    Lite
                  </span>
                </div>
              </div>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="generation_lic_text mrg-btm-50"
              ></textarea>
            </div>
            <div className="btn-container-new-company">
              <ButtonFunctional onClick={generationLicense}>
                Сгенерировать лицензии
              </ButtonFunctional>
              <ButtonFunctional
                onClick={() => setVisibleGenerationBlock(false)}
              >
                Отмена
              </ButtonFunctional>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});
