import React from "react";
import Media from "../../api/Media";
import { observer } from "mobx-react-lite";

const ItemCompany = observer((props) => {
  return (
    <div className="container-item-el">
      <span className="item-company-name item-cosmetic" >
        {window.innerWidth < 460 && props.company.name_company.length > 30
          ? props.company.name_company.slice(0, 30) + " ..."
          : props.company.name_company}
      </span>
      <span className="item-company-inn item-cosmetic">
        {props.company.inn}
      </span>
      {!Media.mobile ? (
        <span className="item-company-address item-cosmetic">
          {props.company.address_company.length < 90
            ? props.company.address_company
            : props.company.address_company.slice(0, 90) + " ........"}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
});

export default ItemCompany;
