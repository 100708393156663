import { makeAutoObservable, toJS } from "mobx";

class RolesState {
  constructor() {
    makeAutoObservable(this);
  }
  // testConsole = () => {
  //   localStorage.removeItem("User");
  // };
  user = JSON.parse(localStorage.getItem("User"));

  checkRight = async (right) => {
    if (localStorage.getItem("User")) {
      return (
        JSON.parse(localStorage.getItem("User")).permission.includes(right) ||
        JSON.parse(localStorage.getItem("User")).is_superuser
      );
    } else {
      return null;
    }
  };

  // Компании
  companys = this.checkRight("get_all_company"); // Все компании
  company_id = this.checkRight("get_company_id"); //Компания по инн
  company_add = this.checkRight("post_company"); // Добавить компанию
  patch_company = this.checkRight("patch_company"); // Изменить компанию
  // Площадки
  production_id = this.checkRight("get_production_id"); // Площадки компании
  patch_production = this.checkRight("patch_production");
  post_production = this.checkRight("patch_production");
  // Лицензии
  license_id = this.checkRight("get_license_id"); // Лицензии компании
  add_license_id = this.checkRight("post_generate");
  check_licensing = this.checkRight("get_pin_id");
  attach_licensing = this.checkRight("patch_attach_id");
  unlink_licensing = this.checkRight("patch_unlink");
  licen_prol_id = this.checkRight("patch_licen_prol_id");
  patch_activity_license_id = this.checkRight("patch_activity_license_id");

  // Подписки
  subscription_id = this.checkRight("get_subscription_id"); // Подписки компании
  patch_subscription = this.checkRight("patch_subscription"); // Изменить подписку компании

  // Софт
  soft_company_id = this.checkRight("get_soft_company_id"); // Софт компании

  // Сотрудники
  emploees = this.checkRight("get_all_user"); //Все сотрудники
  emploee_id = this.checkRight("get_user_id"); //Сотрудник по ид
  update_user = this.checkRight("update_user");
  reset_password = this.checkRight("reset_password");
  patch_relationship = this.checkRight("patch_relationship");
  all_roles = this.checkRight("get_all_roles");

  // Отчёты
  reports = this.checkRight("get_all_soft_client"); // Все отчёты
  all_soft_client = this.checkRight("get_all_soft_client"); // Отчёты по софту
  subscription_rights = this.checkRight("valid_subscription"); // Отчёты по подпискам

  // Профиль
  get_my_profile = this.checkRight("get_my_profile");
  patch_my_profile = this.checkRight("get_my_profile");
  patch_change_password = this.checkRight("patch_change_password");

  // Администрирование
  admin_panel = localStorage.getItem("User")
    ? JSON.parse(localStorage.getItem("User")).permission.includes(
        "post_user"
      ) ||
      JSON.parse(localStorage.getItem("User")).permission.includes(
        "get_roles_id"
      ) ||
      JSON.parse(localStorage.getItem("User")).permission.includes(
        "patch_client_id"
      ) ||
      JSON.parse(localStorage.getItem("User")).is_superuser
    : null; // Админ панель

  readRight = async () => {
    // Компании
    this.companys = await this.checkRight("get_all_company");
    this.company_id = await this.checkRight("get_company_id");
    this.company_add = await this.checkRight("post_company");
    this.patch_company = await this.checkRight("patch_company");

    // Площадки
    this.production_id = await this.checkRight("get_production_id");
    this.patch_production = await this.checkRight("patch_production");
    this.post_production = await this.checkRight("patch_production");

    // Лицензии
    this.license_id = await this.checkRight("get_license_id");
    this.add_license_id = await this.checkRight("post_generate");
    this.check_licensing = await this.checkRight("get_pin_id");
    this.attach_licensing = await this.checkRight("patch_attach_id");
    this.unlink_licensing = await this.checkRight("patch_unlink");
    this.licen_prol_id = await this.checkRight("patch_licen_prol_id");
    this.patch_activity_license_id = await this.checkRight(
      "patch_activity_license_id"
    );

    // Подписки
    this.subscription_id = await this.checkRight("get_subscription_id");
    this.patch_subscription = await this.checkRight("patch_subscription");

    // Софт
    this.soft_company_id = await this.checkRight("get_soft_company_id");

    // Сотрудники
    this.emploees = await this.checkRight("get_all_user");
    this.emploee_id = await this.checkRight("get_user_id");
    this.update_user = await this.checkRight("update_user");
    this.reset_password = await this.checkRight("reset_password");
    this.patch_relationship = await this.checkRight("patch_relationship");
    this.all_roles = await this.checkRight("get_all_roles");

    // Отчёты
    this.all_soft_client = await this.checkRight("get_all_soft_client");
    this.subscription_rights = await this.checkRight("valid_subscription");

    // Профиль
    this.get_my_profile = await this.checkRight("get_my_profile");
    this.patch_my_profile = await this.checkRight("get_my_profile");
    this.patch_change_password = await this.checkRight("patch_change_password");
    // Администрирование
    this.admin_panel =
      JSON.parse(localStorage.getItem("User")).permission.includes(
        "post_user"
      ) ||
      JSON.parse(localStorage.getItem("User")).permission.includes(
        "get_roles_id"
      ) ||
      JSON.parse(localStorage.getItem("User")).permission.includes(
        "patch_client_id"
      ) ||
      JSON.parse(localStorage.getItem("User")).is_superuser;
  };
}

export default new RolesState();
