import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { MyBtnCheckActive } from "../UI/MyBtnCheckActive";
import User from "../../api/User";
import Media from "../../api/Media";
import RolesState from "../../api/RolesState";

export const ItemUserAdminPanel = observer((props) => {
  return (
    <div className="container-item-el">
      <Link
        className="container-item-el"
        style={{ border: "none" }}
        to={`/employees/${props.user.id}`}
      >
        <span className="item_user_admin_name item-cosmetic">
          {props.user.last_name}{" "}
        </span>
        {!Media.mobile ? (
          <span className="item_user_admin_name item-cosmetic">
            {props.user.first_name}{" "}
          </span>
        ) : (
          <></>
        )}
        {!Media.mobile ? (
          <span className="item_user_admin_email item-cosmetic">
            {props.user.email}{" "}
          </span>
        ) : (
          <></>
        )}

        {props.user["role_rel"] !== null ? (
          <span className="item_user_admin_email item-cosmetic">
           {props.user["role_rel"].role_name}
          </span>
        ) : (
          <span className="item_user_admin_email item-cosmetic">
            Роль не назначена
          </span>
        )}
      </Link>
      {User.user.is_superuser || RolesState.admin_panel ? (
        <label className="item_user_admin_10 item-cosmetic">
          {!Media.mobile ? "Активен: " : ""}

          {props.user.is_active ? (
            <MyBtnCheckActive
              onClick={() => props.actionActiveUser(props.user)}
              active={props.user.is_active}
            />
          ) : (
            <MyBtnCheckActive
              onClick={() => props.actionActiveUser(props.user)}
              active={props.user.is_active}
            />
          )}
        </label>
      ) : (
        <></>
      )}
      {User.user.is_superuser ? (
        <label className="item_user_admin_10 item-cosmetic">
          {!Media.mobile ? "Суперюзер: " : ""}

          {props.user.is_superuser ? (
            <MyBtnCheckActive
              onClick={() => props.actionSuperUser(props.user)}
              active={true}
            />
          ) : (
            <MyBtnCheckActive
              onClick={() => props.actionSuperUser(props.user)}
              active={false}
            />
          )}
        </label>
      ) : (
        <></>
      )}
    </div>
  );
});
