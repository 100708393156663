import React, { useEffect } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import ApiData from "../api/ApiData";

export const ExportToExcel = ({ fileName, excel }) => {
  useEffect(() => {
    console.log(excel);
  }, []);

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = () => {
    const reportExcel = excel;
    console.log(excel);
    const ws = XLSX.utils.json_to_sheet(reportExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    console.log("reportExcel", reportExcel);
  };

  return (
    <button className="exportToExcel" onClick={exportToCSV}>
      Экспорт в Excel
    </button>
  );
};
