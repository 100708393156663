import React from "react";
import ReportData from "../../../api/ReportData";
import { ItemVersionReport } from "./ItemVersionReport";
import { observer } from "mobx-react-lite";

import { ContainerSubTitleGrey } from "../../ContainerSubTitleGrey";

export const ListVersionReport = observer(({valueVersion, moveCompany}) => {
  if (ReportData.soft_version.length !== 0) {
    return ReportData.soft_version.map((ver) => (
      <ItemVersionReport key={ver.inn} ver={ver} moveCompany={moveCompany}/>
    ));
  } else {
    return <ContainerSubTitleGrey text={`Не найдено программного обеспечения версии ${valueVersion} или ниже`}/>
  }
});
