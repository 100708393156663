import React, { useState } from "react";
import ApiData from "../../api/ApiData";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { InputEditVer1 } from "../UI/InputEditVer1";
import ButtonFunctionalVer2 from "../UI/ButtonFunctionalVer2";
import Msg from "../../api/Msg";

const NewProduction = observer(({ setFlagNewModalProduction }) => {

  // Переменные площадки
  const [nameProduction, setNameProduction] = useState("");
  const [productionAddress, setProductionAddress] = useState("");

  const setProduction = () => {
    if (nameProduction === "" || productionAddress === "") {
      return Msg.writeMessages("Не все поля заполнены");
    }

    const dataProduction = {
      company_id: Number(toJS(ApiData.company).id),
      name_production: nameProduction,
      address_production: productionAddress,
    };
    console.log(dataProduction);
    ApiData.addProduction(dataProduction);
    setFlagNewModalProduction(false)
  };

  return (
    <div className="posi-abs-mini">
      <span className="li_title">Создание новой площадки</span>
      <div className="mrg-top-12 ">
        <ul className="col-ul-new-card">
          <li>Название</li>
          <li>
            {" "}
            <InputEditVer1
              value={nameProduction}
              onChange={(e) => setNameProduction(e.target.value)}
              placeholder="Введите название площадки"
              style={{ width: "150%" }}
            />
          </li>
          <li className="mrg-top-12 ">Адрес</li>
          <li>
            {" "}
            <InputEditVer1
              value={productionAddress}
              onChange={(e) => setProductionAddress(e.target.value)}
              placeholder="Введите адрес площадки"
              style={{ width: "150%" }}
            />
          </li>
        </ul>
      </div>
      <div className="btn-container-new-company mrg-top-50">
        <ButtonFunctionalVer2 onClick={setProduction}>Сохранить</ButtonFunctionalVer2>
        <ButtonFunctionalVer2 onClick={() => setFlagNewModalProduction(false)}>
          Закрыть
        </ButtonFunctionalVer2>
      </div>
    </div>
  );
});

export default NewProduction;
