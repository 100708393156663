import { observer } from "mobx-react-lite";
import React from "react";

export const ItemClientIdAdmin = observer((props) => {
  if (props.clientid.software_client_rel) {
    return (
      <div className="container-item-el">
        <span className="item_client_id_admin_client">
          {props.clientid.client_id}
        </span>
        <span className="item_client_id_admin_name">
          {props.clientid.software_client_rel.name}
        </span>
        <span className="item_client_id_admin_company">
          {props.clientid.company_rel.name_company}
        </span>
        <span
          className="item_client_id_admin_btn"
          onClick={() => props.deleteClient(props.clientid)}
        >
          Отвязать
        </span>
      </div>
    );
  }
});
