import { observer } from "mobx-react-lite";
import React from "react";
import { MyBtnCheckActive } from "../UI/MyBtnCheckActive";

export const ItemRight = observer((props) => {
  return (
    <li>
      <label className="flx-sb mrg-btm-10 ">
        <span className="role_item_text">{props.right.description_rights}</span>{" "}
        {props.right.activ ? (
          <MyBtnCheckActive
            onClick={() => props.actionRole(props.right)}
            active={props.right.activ}
          />
        ) : (
          <MyBtnCheckActive
            onClick={() => props.actionRole(props.right)}
            active={props.right.activ}
          />
        )}
      </label>
    </li>
  );
});
