import React, { useMemo, useState } from "react";
import FilterData from "../../api/FilterData";
import { observer } from "mobx-react-lite";
import ApiData from "../../api/ApiData";

export const FilterByVersion = observer(
  ({ versionFilterOn, setVersionFilterOn }) => {
    const [valueVersion, setValueVersion] = useState(
      sessionStorage.getItem("soft_version")
        ? sessionStorage.getItem("soft_version")
        : "1.0.4."
    );
    const [clsOnBtn, setClsOnBtn] = useState(
      versionFilterOn ? "btn_active_period" : "btn_deactive_period"
    );
    const [valueBtn, setValueBtn] = useState(versionFilterOn ? "Вкл" : "Откл");

    const hendlerValueVersion = (e) => {
      sessionStorage.removeItem("soft_version")
      setValueVersion(e.target.value);
      FilterData.filterSoftVersionCompany(e.target.value);
    };

    const checkFilterOnBoot = useMemo(() => {
      if (versionFilterOn) {
        FilterData.filterSoftVersionCompany(valueVersion);
      }
    }, [ApiData.clients_soft.length !== 0]);

    const onFilter = () => {
      if (!versionFilterOn) {
        setVersionFilterOn(true);
        setClsOnBtn("btn_active_period");
        setValueBtn("Вкл");
        FilterData.filterSoftVersionCompany(valueVersion);
      } else {
        setVersionFilterOn(false);
        setClsOnBtn("btn_deactive_period");
        setValueBtn("Откл");
        FilterData.showAllClients();
        sessionStorage.removeItem("soft_version")
      }
    };

    const onFilterMemo = useMemo(() => {
      if (!versionFilterOn) {
        setVersionFilterOn(false);
        setClsOnBtn("btn_deactive_period");
        setValueBtn("Откл");
        FilterData.showAllClients();  
        sessionStorage.removeItem("soft_version")

      }
    }, [!versionFilterOn]);

    return (
      <div className="soft_filter_version_container">
        <span className="text_soft_filter_version">Поиск по версии</span>
        <span
          className={clsOnBtn}
          style={{ width: "65px", marginLeft: "5px" }}
          onClick={onFilter}
        >
          {valueBtn}.
        </span>
        {versionFilterOn ? (
          <input
            value={valueVersion}
            onChange={hendlerValueVersion}
            className="inp_soft_filter_version"
            type="text"
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
);
