import { makeAutoObservable } from "mobx";

class Media {
  mobile = false;

  constructor() {
    makeAutoObservable(this);
  }

  handlerResize = (size) => {
    if (size < 1100) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  };
}
export default new Media();
