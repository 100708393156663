import React from 'react'

export const BtnSearch = ({value, onChange, placeholder, style}) => {
  return (
        <input
        placeholder={placeholder}
        type="text"
        className='inp-search-tools'
        value={value}
        onChange={onChange}
        style={style}
      />
  )
}
