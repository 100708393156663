// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.generation_subs_min_block_left {
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  width: 100%;
  /* flex: 0 0 70%; */
  height: 123px;
}
`, "",{"version":3,"sources":["webpack://./src/style/subscription.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,QAAQ;EACR,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".generation_subs_min_block_left {\r\n  padding: 15px;\r\n  border-radius: 5px;\r\n  display: flex;\r\n  flex-direction: column;\r\n  gap: 5px;\r\n  align-items: center;\r\n  width: 100%;\r\n  /* flex: 0 0 70%; */\r\n  height: 123px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
