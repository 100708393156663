import axios from "axios";
import ApiData from "./ApiData";
import config from "../config.json";
import Msg from "./Msg";

export const req = axios.create({
  baseURL: config.url_api,
});

req.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${
    JSON.parse(localStorage.getItem("User"))["access_token"]
  }`;
  return config;
});

req.interceptors.response.use(
  (config) => {
    return config;
  },
  (e) => {
    if (localStorage.getItem("User") === null) {
      ApiData.isAuth = false;
      Msg.writeMessages("Авторизуйтесь, чтобы продолжить работу в ЛК DMC");
      return;
    }
    console.log(e);
    if (axios.isAxiosError(e)) {
      if (e.code === "ERR_NETWORK") {
        Msg.writeMessages("Ошибка: " + e.message + " Попробуйте позже.");
        return (ApiData.isAuth = false);
      }
      if (e.request.status === 401 || localStorage.getItem("User") === null) {
        ApiData.isAuth = false;
        localStorage.removeItem("User");
        Msg.writeMessages(
          "Устаревший токен, пожалуйста, авторизуйтесь повторно."
        );
        return;
      }
      if (e.request.status === 403) {
        console.log(e.request.status);
        Msg.writeMessages(
          "Недостаточно прав для просмотра:" +
            " " +
            e.request.responseURL +
            ". Авторизуйтесь повторно."
        );
        localStorage.removeItem("User");
        return (ApiData.isAuth = false);
      }
      if (e.response.data.message !== undefined) {
        Msg.writeMessages(e.response.data.message);
        console.log(e.response.data.message);
      }
      Msg.writeMessages(e.response.data.detail[0].message);
      throw e;
    }
  }
);
