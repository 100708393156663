import React from "react";
import ApiData from "../../api/ApiData";
import { observer } from "mobx-react-lite";
import { ContainerSubTitleGrey } from "../ContainerSubTitleGrey";
import { GenerationLicense } from "../licensing/GenerationLicense";
import RolesState from "../../api/RolesState";

export const ComponentLicensingCardCompamy = observer(() => {

  return (
    <div className="flex-container_card_company_block_lic">
      {ApiData.licensing_info !== undefined ? (
        <ul className="licensing_card_block">
          <li className="li_title mrg-btm-10">Лицензии компаний на оборудование</li>
          <li className="company_card_block_lic_text">
            Всего лицензий:{" "}
            {(ApiData.licensing_info.count_activ +
              ApiData.licensing_info.count_free) || 0}
          </li>
          <li className="company_card_block_lic_text">
            Лицензий используется : {ApiData.licensing_info.count_activ || 0}
          </li>
          <li className="company_card_block_lic_text">
            Свободных лицензий : {ApiData.licensing_info.count_free || 0}
          </li>
        </ul>
      ) : (
        <ContainerSubTitleGrey
          style={{ fontSize: "25px", maxWidth: "290px" }}
          text="У данного клиента отстутствуют лицензии"
        />
      )}

      {RolesState.add_license_id ? (
        <GenerationLicense />
      ) : (
        <></>
      )}
    </div>
  );
});
