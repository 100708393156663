import React, { useEffect, useState } from "react";
import ApiData from "../../api/ApiData";
import { observer } from "mobx-react-lite";
import Loader from "../Loader";
import { ItemChartComponent } from "./ItemChartComponent";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import ChartStory from "../../story/ChartStory";
import { ContainerSubTitleGrey } from "../ContainerSubTitleGrey";
import { TemplateCountTextReport } from "./text/TemplateCount.jsx/TemplateCountTextReport";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);

export const ChartContainer = observer(() => {
  // Флаги
  const options = {
    layout: {
      padding: {
        bottom: 100,
      },
    },
  };
  const [defectChartDetail, setDefectChartDetail] = useState(false);
  //
  useEffect(() => {
    if (ChartStory.inn_company !== ApiData.company.inn) {
      ChartStory.getCompanyGraph();
    }
  }, []);

  const defectDetail = () => {
    !defectChartDetail
      ? setDefectChartDetail(true)
      : setDefectChartDetail(false);
  };

  if (ChartStory.observableChartCompany) {
    return <Loader />;
  } else if (
    !ChartStory.observableChartCompany &&
    ChartStory.inn_company === null
  ) {
    return <ContainerSubTitleGrey text="Нет выгруженных данных" />;
  } else {
    return (
      <div className="chart_container">
        <TemplateCountTextReport />
        <ItemChartComponent name="Печать по типам шаблонам">
          <Bar data={ChartStory.PRINT} />
        </ItemChartComponent>
        {/* <ItemChartComponent name="Линейный график">
          <Line data={ChartStory.PRINT} />
        </ItemChartComponent> */}
        {!defectChartDetail ? (
          <ItemChartComponent
            defectDetail={defectDetail}
            defect={true}
            name="График по браку и верификации"
          >
            <Pie data={ChartStory.VERIFY_DEFECT} />
          </ItemChartComponent>
        ) : (
          <ItemChartComponent
            defectDetail={defectDetail}
            defect={true}
            name="График по браку и верификации"
          >
            <Pie options={options} data={ChartStory.DEFECT} />
          </ItemChartComponent>
        )}{" "}
      </div>
    );
  }
});
