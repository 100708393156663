import React, { useEffect, useLayoutEffect, useState } from "react";
import User from "../api/User";
import { Role } from "../components/admin/Role";
import { UsersAdminPanel } from "../components/admin/UsersAdminPanel";
import { observer } from "mobx-react-lite";
import Loader from "../components/Loader";
import ApiData from "../api/ApiData";
import { Navigate } from "react-router-dom";
import { ClientIdAdmin } from "../components/admin/client_id/ClientIdAdmin";
import RolesState from "../api/RolesState";

const AdminPanel = observer(() => {
  const [usersRights, setUsersRights] = useState(
    User.user.permission.includes("post_user") || User.user.is_superuser
  );
  const [rolesRights, setRolesRights] = useState(
    User.user.permission.includes("get_roles_id") || User.user.is_superuser
  );
  const [clientIdRights, setClientIdRights] = useState(
    User.user.permission.includes("patch_client_id") || User.user.is_superuser
  );
  const [classBtnNavUsers, setClassBtnNavUsers] = useState(
    "nav-card-btn-active"
  );
  const [classBtnNavRole, setClassBtnNavRole] = useState("nav-card-btn");
  const [classBtnNavClientId, setClassBtnNavClientId] =
    useState("nav-card-btn");
  const [userFlag, setUserFlag] = useState(false);
  const [roleFlag, setRoleFlag] = useState(false);
  const [clientIdFlag, setClientIdFlag] = useState(false);

  const navUsers = () => {
    setUserFlag(true);
    setRoleFlag(false);
    setClientIdFlag(false);
    setClassBtnNavUsers("nav-card-btn-active");
    setClassBtnNavRole("nav-card-btn");
    setClassBtnNavClientId("nav-card-btn");
  };
  const navRole = () => {
    setUserFlag(false);
    setRoleFlag(true);
    setClientIdFlag(false);
    setClassBtnNavUsers("nav-card-btn");
    setClassBtnNavRole("nav-card-btn");
    setClassBtnNavClientId("nav-card-btn");
  };

  const navClientId = () => {
    setUserFlag(false);
    setRoleFlag(false);
    setClientIdFlag(true);
    setClassBtnNavUsers("nav-card-btn");
    setClassBtnNavRole("nav-card-btn");
    setClassBtnNavClientId("nav-card-btn-active");
  };

  useLayoutEffect(() => {
    if (usersRights) {
      setUserFlag(true);
    }
    if (rolesRights && !usersRights) {
      setRoleFlag(true);
    }
    if (clientIdRights && !rolesRights && !usersRights) {
      setClientIdFlag(true);
    }
  }, []);
  useEffect(() => {
    ApiData.getDataEmployees();
  }, []);

  if (RolesState.admin_panel) {
    return (
      <div className="main-page">
        {!ApiData.observableUser ? (
          <>
            <h1 className="card-title">Панель администратора</h1>
            <div className="container_title_edit">
              {usersRights ? (
                <span className={classBtnNavUsers} onClick={navUsers}>
                  Пользователи
                </span>
              ) : (
                <></>
              )}
              {rolesRights ? (
                <span className={classBtnNavRole} onClick={navRole}>
                  Роли
                </span>
              ) : (
                <></>
              )}
              {clientIdRights ? (
                <span className={classBtnNavClientId} onClick={navClientId}>
                  Клиенты ИД
                </span>
              ) : (
                <></>
              )}
            </div>
            {userFlag ? <UsersAdminPanel /> : <></>}
            {roleFlag ? <Role /> : <></>}
            {clientIdFlag ? <ClientIdAdmin /> : <></>}
          </>
        ) : (
          <Loader />
        )}
      </div>
    );
  } else {
    return <Navigate to="/norights" />;
  }
});
export { AdminPanel };
